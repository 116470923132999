import { ModuleWithProviders, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PagesComponent } from '../app/pages/pages.component';
import { AuthGuard } from './pages/auth-guard/auth.guard';
import { CompanyListComponent } from './pages/company-list/company-list.component';
import { FormDetailsComponent } from './pages/form-details/form-details.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { QuestionComponent } from './pages/question/question.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { SettingComponent } from './pages/setting/setting.component';
import { UserdirectoryComponent } from './pages/userdirectory/userdirectory.component';
import { VideoreportsComponent } from './pages/videoreports/videoreports.component';
import { CategoryComponent } from './pages/category/category.component';
import { CategoryReportsComponent } from './pages/category-reports/category-reports.component';
import { TestCategoryComponent } from './pages/test-category/test-category.component';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: HomeComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'userdirectory', component: UserdirectoryComponent },
      { path: 'project-details', component: FormDetailsComponent },
      { path: 'reports', component: ReportsComponent },
      { path: 'question', component: QuestionComponent },
      { path: 'company-list', component: CompanyListComponent },
      { path: 'videoreports', component: VideoreportsComponent },
      { path: 'setting', component: SettingComponent },
      { path: 'category', component: CategoryComponent },
      { path: 'test-category', component: TestCategoryComponent },
      { path: 'category-reports', component: CategoryReportsComponent }
    ],

  },

  { path: 'login', component: LoginComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, {
  // <- uncomment this line for disable lazy load
 useHash:true
});