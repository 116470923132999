import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomValidators } from 'ng2-validation';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from './login.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public forgetPasswordform: FormGroup;
  public loginData;

  constructor(private frombuilder: FormBuilder,
    private loginservice: LoginService,
    private router: Router,
    private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.getFormValidation();
  }

  getFormValidation() {
    this.loginForm = this.frombuilder.group({
      'email': ['', Validators.compose([Validators.required, CustomValidators.email])],
      'password': ['', Validators.compose([Validators.required, Validators.minLength(6)])]
    });
    this.forgetPasswordform = this.frombuilder.group({
      'forg_email': ['', Validators.compose([Validators.required, CustomValidators.email])]
    })

  }


  openPasswordModal() {
    ($('#forgot-password') as any).modal('show');
  }



  onSubmit(values: Object): void {
    console.log(this.loginForm.value);
    if (this.loginForm.valid) {
      this.login(this.loginForm.value);
    }
  }


  public login(user) {
    this.loginData = [];
    this.loginservice.login(user).subscribe(res => {
      if (res.success == 1) {
        this.loginData = res;
        localStorage.setItem('currentUser', JSON.stringify(this.loginData));
        localStorage.setItem('currentUserId', this.loginData['data']['id']);
        localStorage.setItem('currentUserName', this.loginData['data']['name']);
        localStorage.setItem('currentUserRoleId', this.loginData['data']['role']);
        localStorage.setItem('user_profile_pic', this.loginData['data']['profile_pic']);
        this.toastrService.success(res.message);
        this.router.navigate(['/']);
      } else {
        this.toastrService.error(res.message);
        return false;
      }
    }, error => {
      console.log("Error", error);
    });
  }


}
