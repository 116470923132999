import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CompanyListService } from './company-list.service';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {

  public loader = true;
  addCompany: FormGroup;
  editCompany: FormGroup;
  public companyList: any;
  public selectedcompany: any;
  public selectedCompanyId: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private companylistser: CompanyListService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.formValidation();
    this.getCompanyList();
  }

  formValidation() {
    this.addCompany = this.formBuilder.group({
      company_name: new FormControl('', [
        Validators.required
      ])
    });
    this.editCompany = this.formBuilder.group({
      company_name: new FormControl('', [
        Validators.required
      ])
    });
  }

  getCompanyList() {
    this.companylistser.companyList().subscribe(res => {
      if (res.success == 1) {
        this.companyList = res.data;
        this.loader = false;
      }
    })
  }


  addCompanyModal() {
    this.addCompany.reset();
    ($('#addcompanyModal') as any).modal('show');
  }

  saveCompany(company) {
    let Obj = {
      name: company.company_name
    };
    this.companylistser.addCompany(Obj).subscribe(res => {
      if (res.success == 1) {
        this.toastrService.success(res.message);
        ($('#addcompanyModal') as any).modal("hide");
        this.getCompanyList();
      }
      else {
        this.toastrService.error(res.message);
        ($('#addcompanyModal') as any).modal("hide");
        return false;
      }
    }, error => {
      ($('#addcompanyModal') as any).modal('hide');
      return false;
    })
  }


  companyEditModel(company) {
    this.selectedcompany = company;
    ($('#editcompanyModal') as any).modal('show');
    console.log(company);
    this.editCompany.patchValue(
      {
        'company_name': company.company_name,
      }
    );
    console.log(this.editCompany);
  }

  updateCompany(companydata) {
    console.log(companydata);
    let Obj = {
      company_id: this.selectedcompany.id,
      name: companydata.company_name
    }
    console.log(Obj);
    this.companylistser.editCompany(Obj).subscribe((res: any) => {
      if (res.success == 1) {
        this.toastrService.success(res.message);
        ($('#editcompanyModal') as any).modal("hide");
        this.getCompanyList();
      }
      else {
        this.toastrService.error(res.message);
        ($('#editcompanyModal') as any).modal("hide");
        return false;
      }
    }, error => {
      ($('#editcompanyModal') as any).modal('hide');
      return false;
    }
    )
  }


  companydeleteModal(id) {
    this.selectedCompanyId = id;
    ($('#deleteComapny') as any).modal('show');
  }

  deleteCompanyConfirm() {
    let Obj = {
      company_id: this.selectedCompanyId
    }
    console.log(this.selectedCompanyId);

    this.companylistser.deleteCompany(Obj).subscribe(res => {
      if (res.success == 1) {
        ($('#deleteComapny') as any).modal("hide");
        this.toastrService.success(res.message);
        this.getCompanyList();
      } else {
        this.toastrService.error(res.message);
      }
    })
  }

}
