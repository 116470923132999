import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType, HttpEvent } from '@angular/common/http';
import { FormArray, FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { HomeService } from './home.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';


// interface Company {
//   id: string;
//   company_name: string;
//   checked: boolean; // Add the checked property
// }

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild('fileInput', { static: false }) fileInput;

  public addProjectForm: FormGroup;
  public editProjectForm: FormGroup;
  public addVideoForm: FormGroup;
  public editVideoForm: FormGroup;
  public importFileForm: FormGroup;
  public projects: any;
  public companyData: any;
  public videodetail: any;
  public selectedproject: any;
  public deletprojectId: any;
  public videoDeletetId: any;
  public loader = true;
  public isDisplay = false;
  public showMe: boolean = true;
  public showMew: boolean = true;
  public is_Process: boolean;
  public project_id;
  public video_project_id;
  public qrCodeEng: any;
  public qrCodeSpn: string;
  public uploadvideo: any;
  selected: boolean = false;
  selected_icra: boolean = false;
  isButtonVisible: boolean = true;
  addWorkflowLoad: boolean;
  addWorkflowLoadd: boolean;
  videos: FormArray | any;
  is_invalid: any;
  is_uploaded: any = 0;
  Language = [
    { lang_id: 1, value: 'English' },
    { lang_id: 2, value: 'Spanish' },
  ];
  selectedvideodetails: any;
  sizeError: any = [];

  categoryDetailsData: any;
  constructor(private toastrService: ToastrService,
    private fromBuilder: FormBuilder,
    private homeService: HomeService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.formValidatation();
    this.getProjectList();
    // this.videos.push(this.home);
    this.getCategoryLists();
  }

  ngAfterViewChecked() {
    //your code to update the model
    this.cdr.detectChanges();
  }

  public showLoader() {
    jQuery('.custom-loader').show();
  }
  public hideLoader() {
    jQuery('.custom-loader').hide();
  }

  next() {
    this.showMew = !this.showMew;
  }

  formValidatation() {
    this.addProjectForm = this.fromBuilder.group({
      project_number: new FormControl('', [
        Validators.required
      ]),
      project_name: new FormControl('', [
        Validators.required
      ]),
      // vaccination_att: new FormControl('', []),
      icra_videos: new FormControl('', []),
    });
    this.editProjectForm = this.fromBuilder.group({
      project_number: new FormControl('', [
        Validators.required
      ]),
      project_name: new FormControl('', [
        Validators.required
      ]),
      // vaccination_att: new FormControl('', []),
      icra_videos: new FormControl('', []),
      addVideoForm: this.fromBuilder.group({
        videos: this.fromBuilder.array([this.createItem()])
      })
      // video_name: new FormControl('', [
      //   Validators.required
      // ]),
      // lang: new FormControl('', [
      //   Validators.required
      // ]),
      // video_thumb_url: new FormControl('', [
      //   Validators.required
      // ]),
    });
    this.addVideoForm = this.fromBuilder.group({
      videos: this.fromBuilder.array([this.createItem()])
    });

    this.importFileForm = this.fromBuilder.group({
      import_file: [""],
      assign_company: [""],
    })
  }

  toggleView() {
    this.showMe = !this.showMe
  }

  addProjectModel() {
    this.sizeError = [];
    this.showMew = true;
    this.addProjectForm.reset();
    this.addVideoForm.reset();
    this.selected = false;
    this.selected_icra = false;
    ($('#addModal') as any).modal('show');
    this.reset();
  }

  createItem(): FormGroup {
    return this.fromBuilder.group({
      video_name: new FormControl('', [
        Validators.required
      ]),
      uploadvideo: new FormControl('', [
        Validators.required
      ]),
      lang: new FormControl(null, [
        Validators.required
      ]),
      progress: 0,
    });
  }


  filename: any;
  getProjectList() {
    let Obj = {
      'user_id': localStorage.getItem('currentUserId')
    }
    this.homeService.getProjectList(Obj).subscribe(res => {
      if (res.success == 1) {
        this.projects = res.data;
        this.companyData = res.data;
        this.loader = false;
        console.log("this.projects->", this.projects);
        console.log("this.companyData->", this.companyData);
      }
    })
  }


  getsetProjectList() {
    let Obj = {
      'user_id': localStorage.getItem('currentUserId')
    }
    this.homeService.getProjectList(Obj).subscribe(res => {
      if (res.success == 1) {
        let finalarray = res.data.filter(
          videos => videos.id === this.video_project_id);
        this.selectedvideodetails = finalarray[0].videos;
        this.loader = false;
        console.log("finalarray", finalarray);
      }
    })
  }


  saveProject(userdata) {
    this.addWorkflowLoad = true;
    let Obj = {
      'project_number': userdata.project_number,
      'project_name': userdata.project_name,
      // 'vaccination_att': userdata.vaccination_att ? 1 : 0,
      // 'icra_videos': userdata.icra_videos ? 1 : 0
      // 'vaccination_att': "1" ,
      'icra_videos': "1" 
    }
    this.homeService.addNewProject(Obj).subscribe(res => {
      if (res.success == 1) {
        this.addWorkflowLoad = false;
        this.video_project_id = res.project_id;
        console.log(this.video_project_id);
        this.toastrService.success(res.message);
        this.getProjectList();
        this.showMew = !this.showMew;
        this.videos = this.addVideoForm.get('videos') as FormArray;
        console.log(this.videos.controls[0]);
        this.videos.controls[0].patchValue({
          'progress': 0
        });
        this.is_Process = true;
        // this.videos[0].reset();
        // ($('#addModal') as any).modal('hide');
      }
      else {
        this.toastrService.error(res.message);
        // ($('#addModal') as any).modal('hide');
        return false;
      }
    }, error => {
      // ($('#addModal') as any).modal('hide');
      return false;
    })
    console.log(userdata);
  }

  openEditModel(event, project) {
    event.preventDefault();
    event.stopPropagation();
    this.addWorkflowLoadd = false;
    this.selectedproject = project;
    this.video_project_id = project.id;
    this.is_Process = true;
    this.sizeError = [];
    this.editProjectForm.reset();
    this.videos = this.editProjectForm.controls.addVideoForm.get('videos') as FormArray;
    this.videos.clear();
    this.videos.push(this.createItem());
    ($('#EditModal') as any).modal('show');
    console.log(project);
    this.selectedvideodetails = project.videos;
    this.editProjectForm.patchValue({
      'project_number': project.project_number,
      'project_name': project.project_name,
      // 'vaccination_att': project.vaccination_att,
      // 'icra_videos': project.icra_videos,
      // 'vaccination_att': "1",
      'icra_videos': "1",
      // 'video_name':project.videos[0].video_name,
      // 'lang':project.videos[0].lang_id,
      // 'video_thumb_url':project.videos[0].video_thumb_url
    })
    console.log(this.editProjectForm);
  }



  updateProject(projectdata) {
    console.log(projectdata);
    this.addWorkflowLoadd = false;
    let Obj = {
      project_id: this.selectedproject.id,
      project_number: projectdata.project_number,
      project_name: projectdata.project_name,
      // vaccination_att: projectdata.vaccination_att,
      // icra_videos: projectdata.icra_videos
      // vaccination_att: "1",
      icra_videos: "1"
    }
    this.homeService.updateProject(Obj).subscribe(res => {
      if (res.success == 1) {
        this.toastrService.success(res.message);
        this.is_Process = false;
        this.addWorkflowLoadd = false;
        ($('#EditModal') as any).modal('hide');
        this.getProjectList();
      }
      else {
        this.toastrService.error(res.message);
        ($('#EditModal') as any).modal('hide');
        return false;
      }
    }, error => {
      ($('#EditModal') as any).modal('hide');
      return false;
    })
  }

  deleteProject(event, id) {
    event.preventDefault();
    event.stopPropagation();
    this.deletprojectId = id;
    ($('#deleteproject') as any).modal('show');
    this.hideLoader();
  }

  deleteProjectConfirm() {
    this.showLoader();
    // this.hideLoader();
    let Obj = {
      project_id: this.deletprojectId,
    }
    console.log(this.deletprojectId);
    this.homeService.deleteProject(Obj).subscribe(res => {
      if (res.success == 1) {
        this.toastrService.success(res.message);
        ($('#deleteproject') as any).modal('hide');
        this.getProjectList();
        this.hideLoader();
      }
      else {
        this.toastrService.error(res.message);
        ($('#deleteproject') as any).modal('hide');
        this.hideLoader();
        return false;
      }
    })
  }

  display() {
    this.isDisplay = !this.isDisplay;
  }

  openFormDetails(userData) {
    console.log(userData);
    this.project_id = userData['id'];
    localStorage.setItem('currentUser_projectId', userData['id']);
    localStorage.setItem('currentUser_projectName', userData['project_name']);
    // this.router.navigate(['/form-details/' + project.id]);
  }


  onFilteredData: any[] = [];
  public getCategoryLists() {
    this.homeService.getCategotyDetails().subscribe(
      {
        next: (res: any) => {
          if (res.success == 1) {
            this.onFilteredData = [];
            this.categoryDetailsData = res.data;
            console.log("this.categoryDetailsData=>", this.categoryDetailsData);

            this.onFilteredData = this.categoryDetailsData.filter(item => item.is_default === 1);
          }
          else {
          }
        },
        error: (error: any) => { }
      });
  }

  onGetProjectId: any;
  openSignUp(event: any, project: any) {
    event.preventDefault();
    event.stopPropagation();
    console.log("project=>>>>>", project.id);
    this.onGetProjectId = project.id
    console.log("this.onGetProjectId=>>>>>", this.onGetProjectId);

    // console.log(environment.apiUrl + '/project_pdf?project_id=' + project.id);
    // window.open(environment.apiUrl + '/project_pdf?project_id=' + project.id, "_self")

    // let link = document.createElement('a');
    // link.setAttribute('target', '_blank');
    // link.setAttribute('href', '/assets/Testing.pdf');
    // link.setAttribute('download', 'Testing.pdf');
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

    ($('#showCateoryList') as any).modal('show');


  }


  onRowClick(catlist: any) {
    console.log("Row clicked with category:", catlist.id);
    console.log("Row clicked with this.onGetProjectId:", this.onGetProjectId);

    event.preventDefault();
    event.stopPropagation();
    console.log(environment.apiUrl + '/download_qr_pdf?cat_id=' + catlist.id + '&project_id=' + this.onGetProjectId);
    // window.open(environment.apiUrl + '/download_qr_pdf?cat_id=' + catlist.id + '&project_id='+ this.onGetProjectId, "_self");
    window.open(environment.apiUrl + '/download_qr_pdf?cat_id=' + catlist.id + '&project_id=' + this.onGetProjectId, "blank");

    ($('#showCateoryList') as any).modal('hide');
  }

  qrVideoPopup(event: any) {
    event.preventDefault();
    event.stopPropagation();
    console.log(environment.apiUrl + '/project_video_pdf?project_id=' + this.onGetProjectId);
    window.open(environment.apiUrl + '/project_video_pdf?project_id=' + this.onGetProjectId, "_self")
  }

  openQRModal(event, project) {
    event.preventDefault();
    event.stopPropagation();
    console.log(environment.apiUrl + '/project_pdf?project_id=' + project.id);
    window.open(environment.apiUrl + '/project_pdf?project_id=' + project.id, "_self")
  }

  qrVideoModal(event, project) {
    event.preventDefault();
    event.stopPropagation();
    console.log(environment.apiUrl + '/project_video_pdf?project_id=' + project.id);
    window.open(environment.apiUrl + '/project_video_pdf?project_id=' + project.id, "_self")
  }

  addVideo(form: any) {
    this.videos = form.get('videos') as FormArray;
    this.is_invalid = 0;
    this.videos.controls.forEach(element => {
      if (element.status == "INVALID") {
        this.is_invalid++;
      }
    });
    console.log(this.is_invalid);
    if (this.is_invalid == 0) {
      let videodata = this.createItem();
      this.videos.push(videodata);
      console.log(videodata);
      console.log(this.videos.controls[this.videos.controls.length - 1]);
    }
  }

  removeRow(i: any, form: any) {
    this.videos = form.get('videos') as FormArray;
    if (form.controls.videos.controls.length > 1) {
      this.videos.removeAt(i);
    }
  }

  removeVideo(id) {
    this.videoDeletetId = id;
    ($('#deletevideo') as any).modal('show');
    console.log(this.videoDeletetId);
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to delete this Video?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ea6a43',
      cancelButtonColor: '#707070',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.homeService.deleteVideo({ id: this.videoDeletetId, project_id: this.video_project_id }).subscribe(res => {
          if (res.success == 1) {
            Swal.fire(
              'Deleted',
              'Video has been delete successfully.',
              'success'
            )
            this.getsetProjectList();
          }
          else {
            this.toastrService.error(res.message);
          }
        }, error => {
          this.toastrService.error(error.error.message);
        })
      }

    })

  }

  uploadVideos(form) {
    this.videos = form.get('videos') as FormArray;
    for (let i = 0; i < this.videos.controls.length; i++) {
      if (this.videos.controls[i].valid) {
        this.addWorkflowLoadd = true;
        let obj = Object.assign({}, (this.videos.controls[i].value));
        console.log(this.videos.controls[i].value.uploadvideo);
        console.log(obj);
        const fd: FormData = new FormData();
        fd.append('video_name', obj.video_name);
        fd.append('lang', obj.lang);
        fd.append('uploadvideo', obj.uploadvideo[0]);
        fd.append('project_id', this.video_project_id);
        fd.append('user_id', localStorage.getItem('currentUserId'));
        this.homeService.uploadVideo(fd).subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              console.log('Request has been made!');
              break;
            case HttpEventType.ResponseHeader:
              console.log('Response header has been received!');
              break;
            case HttpEventType.UploadProgress:
              this.videos.controls[i].value.progress = Math.round(event.loaded / event.total * 100);
              console.log("form upload percentage", this.videos.controls[i].value.progress);
              break;
            case HttpEventType.Response:
              console.log('User successfully created!', event.body);
              event.body.response;
              console.log(event.body.success);
              if (event.body.success == 1) {
                this.is_uploaded = 1;
                console.log(event.body)
                this.addWorkflowLoadd = false;
                this.toastrService.success(event.body.message);
                this.is_Process = false;
                this.isButtonVisible = false;
                // $("#videoFile").attr('disabled', 'disabled');
                // $("#videoTitle").attr('disabled', 'disabled');             
                // this.videos.controls[i].disable();
                this.videos = this.editProjectForm.controls.addVideoForm.get('videos') as FormArray;
                this.videos.clear();
                this.videos.push(this.createItem());
                this.homeService.getProjectList({ 'user_id': localStorage.getItem('currentUserId') }).subscribe(res => {
                  if (res.success == 1) {
                    this.projects = res.data;
                    this.loader = false;
                    let currentProject = this.projects.filter(item => {
                      return item.id == this.video_project_id
                    });
                    this.selectedvideodetails = currentProject[0].videos;
                    console.log(this.selectedvideodetails);
                  }
                })
              }
              else {
                this.addWorkflowLoadd = false;
                this.toastrService.error(event.body.message);
                console.log(event.body.message);
              }
          }
        }, error => {
          this.addWorkflowLoadd = false;
          console.log(error);
          this.toastrService.error(error.error.message);
        })
      }
    }
  }

  reset() {
    this.addWorkflowLoadd = false;
    this.selected = false;
    this.selected_icra = false;
    this.isButtonVisible = true;
    this.videos = this.addVideoForm.get('videos') as FormArray;
    this.videos.clear();
    this.videos.push(this.createItem());
  }

  upload(e, index, form) {
    let file = e.target.files;
    if (file[0].size <= 314572800) {
      this.sizeError[index] = false;
      this.videos = form.get('videos') as FormArray;
      this.videos.controls[index].patchValue({
        'uploadvideo': file
      })
    }
    else {
      this.sizeError[index] = true;
    }

  }

  setting() {
    this.router.navigate(['/setting'])
  }

  downloadImg() {

  }

  onImportProjectId: any;
  openImportModal(event: any, project: any) {
    this.getCompanyData(project.id);
    console.log("in import project id->", project.id);
    this.onImportProjectId = project.id;
    console.log("in import this.onImportProjectId=>>>>>", this.onImportProjectId);
    event.preventDefault();
    event.stopPropagation();
    this.files = [];
    this.newCompanyName = "";

    // ($('#importFileModal') as any).modal('show');
    setTimeout(() => {
      ($('#importFileModal') as any).modal('show');
    }, 300); // Delay for 0.3 seconds
  }

  files: any;
  onSelect(event) {
    console.log("in onSelect event->", event);
    this.files.push(...event.addedFiles);
    console.log("this.files->", this.files);
    console.log("this.files[0]->", this.files[0]);

    // const reader = new FileReader();

    // if (event.target.files && event.target.files.length) {
    //   const [file] = event.target.files;
    //   this.files = event.target.files[0];
    //   reader.readAsDataURL(file);
    // }

  }

  onRemove(event) {
    // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
    console.log("this,file in remove functions->", this.files);

  }


  getCompanyNameData: any[] = []
  filteredCompanyData: any[] = [];
  getCompanyData(projectId: any) {
    let companyObj = {
      project_id: projectId
    }
    this.showLoader();
    this.homeService.getAssignCompnayList(companyObj).subscribe(res => {
      if (res.success == 1) {
        console.log("res.data", res.data);

        this.getCompanyNameData = res.data;
        this.filteredCompanyData = this.getCompanyNameData;
        // this.filteredCompanyData = this.getCompanyNameData.filter(company =>
        //   company.company_name.toLowerCase().includes(this.newCompanyName.toLowerCase())
        // );
        this.hideLoader();
      } else {
        this.hideLoader();
      }
    })
  }

  filterCompanyData() {
    this.filteredCompanyData = this.getCompanyNameData.filter(company =>
      company.company_name.toLowerCase().includes(this.newCompanyName.toLowerCase())
    );
  }


  selectedCompany: any = [];
  checkOne(company, e) {
    this.selectedCompany = [];
    company.checked = e.target.checked;
    var selectallbtn = Array();
    var t = this.getCompanyNameData.filter(opt => opt.checked)
    selectallbtn = t;
    selectallbtn.forEach(element => {
      this.selectedCompany.push({ id: element.id, company_name: element.company_name, checked: element.checked });
    });
    console.log("checked => selectedCompany value->", this.selectedCompany)
  }


  newCompanyName: string = '';
  addCompany() {
    if (this.newCompanyName.trim() !== '') {
      const newCompany = {
        id: "",
        company_name: this.newCompanyName,
        checked: true
      };
      console.log("newCompany->", newCompany);

      this.getCompanyNameData.unshift(newCompany);
      this.selectedCompany.push(newCompany)
      this.filteredCompanyData = [...this.getCompanyNameData];
      console.log("this.getCompanyNameData=>", this.getCompanyNameData);
      console.log("in add this.selectedCompany=>", this.selectedCompany);
      this.newCompanyName = '';
    }
  }

  onCompanySubmit() {
    let companyObj =
    {
      import_file: this.files,
      // assign_company: this.getCompanyNameData,
      // assign_company: this.selectedCompany,
      project_id: this.onImportProjectId,
    }
    console.log("this.getCompanyNameData->", this.getCompanyNameData);
    console.log("this.getCompanyNameData JSON.stringify()->", JSON.stringify(this.getCompanyNameData));
    // console.log("companyObj->", companyObj);

    // let company = [...this.selectedCompany]
    // console.log("company->", company);

    var selectallbtn = Array();
    var t = this.getCompanyNameData.filter(opt => opt.checked)
    selectallbtn = t;
    console.log("selectallbtn->",selectallbtn);
    

    let importFiles;
    if (this.files[0] && this.files[0]) {
      importFiles = this.files[0];
    }
    else {
      importFiles = [];
    }

    //   {
    //     "import_file": {},
    //     "assign_company": "[{\"id\":\"\",\"company_name\":\"test S\",\"checked\":true},{\"id\":1,\"company_name\":\"HSM\",\"checked\":true},{\"id\":2,\"company_name\":\"Skiles Group\",\"checked\":true}]",
    //     "project_id": "817"
    // }


    let formData = new FormData();
    // formData.append('import_file', this.files[0]);
    // formData.append('assign_company', JSON.stringify(this.selectedCompany));
    formData.append('import_file', importFiles);
    // formData.append('assign_company', JSON.stringify(company));
    formData.append('assign_company', JSON.stringify(this.getCompanyNameData));
    formData.append('project_id', this.onImportProjectId);

    // for formData log
    let AssignCompanyData = {};
    formData.forEach((value, key) => {
      AssignCompanyData[key] = value;
    });
    console.log("on Submit->>>>AssignCompanyData=>", AssignCompanyData);


    this.homeService.addAssignCompanyData(formData).subscribe(res => {
      if (res.success == 1) {
        this.toastrService.success(res.message);
        ($('#importFileModal') as any).modal("hide");
        this.getProjectList();
      } else {
        this.toastrService.error(res.message);
      }
    })

  }

  downloadSampleFile() {
    // let sampleFilePath = 'assets/Project_Directory.csv'src\assets\User Guide_ Assigning Company to Project.pdf;
    let sampleFilePath = 'assets/User Guide_ Assigning Company to Project.pdf';
    let link = document.createElement('a');
    link.setAttribute('target', 'blank');
    link.setAttribute('href', sampleFilePath);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }



}
