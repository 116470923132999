import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormDetailsService {

  constructor(private http: HttpClient) { }

  getSubmittedForm(Obj) {
    return this.http.post<any>(environment.apiUrl + '/get_submitted_forms', Obj
    )
  }

  getVideoSubmittedForm(Obj) {
    return this.http.post<any>(environment.apiUrl + '/get_submitted_video_forms', Obj
    )
  }

  // exportExcel(p_id,fDate,tDate){
  //   return this.http.get<any>(environment.apiUrl +'/export_excel_date?project_id='+p_id+'&from='+fDate+'&to='+tDate);
  // }


  public getCategoryDetails() {
    return this.http.get<any>(environment.apiUrl + '/get_category_list')
  }

  public getCategoryDetailsByCatId(catIdObj: any) {
    return this.http.post<any>(environment.apiUrl + '/get_cat_submitted_forms', catIdObj)
  }

}
