import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ReportsService } from './reports.service';
import { FormGroup, FormArray, FormBuilder, Validators } from "@angular/forms";
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';
import { IAngularMyDpOptions } from 'angular-mydatepicker';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  public userDetail = JSON.parse(localStorage.getItem('userDetails'));
  public Question = JSON.parse(localStorage.getItem('questionList'));
  public originalUserDetail: any;
  public yesCount = 0;
  public question_ans: any = [];
  public project_name = localStorage.getItem('currentUser_projectName');

  constructor(private reportService: ReportsService,
    private fromBuilder: FormBuilder,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    console.log(this.Question)
    console.log(this.userDetail);
    this.userDetail.questions.forEach(element => {
      element.forEach(ele => {
        element.created_at = ele.created_at;
        if (ele.answer != "No") {
          this.yesCount++;
        }
      });
      if (this.yesCount >= 1) {
        element.is_symptomatic = "yes";
      }
      else {
        element.is_symptomatic = "No";
      }
    })
    this.originalUserDetail = JSON.parse(JSON.stringify(this.userDetail));

  }

  ngAfterViewChecked(){
    //your code to update the model
    this.cdr.detectChanges();
 }

  public start_date: any;
  public end_date:any;
 
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: 'dd-mm-yyyy',
  };
  public endDateOptions: IAngularMyDpOptions = {
    dateFormat: 'dd-mm-yyyy',
  };

  onStartDateChange() {
    if (this.start_date != null) {
      const startDate = formatDate(this.start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
      this.endDateOptions = {
        ...this.endDateOptions,
        disableUntil: {
          year: this.start_date.singleDate.date.year,
          month: this.start_date.singleDate.date.month,
          day: this.start_date.singleDate.date.day - 1
        }
      };
      // If end date is before start date, reset end date
      if (this.end_date && this.end_date.singleDate.jsDate < this.start_date.singleDate.jsDate) {
        this.end_date = null;
      }
    }
    // Apply filter when both start and end dates are selected
    if (this.start_date != null && this.end_date != null) {
      let startDate = formatDate(this.start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
      let toDate = formatDate(this.end_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
      this.userDetail.questions = this.originalUserDetail.questions.filter(item => {

        let date = formatDate(item[0].created_at, 'yyyy-MM-dd', 'en-US');
        return date > startDate && date < toDate;

      });
      this.userDetail.questions.forEach(element => {
        element.forEach(ele => {
          element.created_at = ele.created_at;
          if (ele.answer != "No") {
            this.yesCount++;
          }
        });
        if (this.yesCount >= 1) {
          element.is_symptomatic = "yes";
        }
        else {
          element.is_symptomatic = "No";
        }
      });


    }
  }

  onFilter() {

    if (this.start_date != null && this.end_date != null) {
      let startDate = formatDate(this.start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
      let toDate = formatDate(this.end_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
      this.userDetail.questions = this.originalUserDetail.questions.filter(item => {

        let date = formatDate(item[0].created_at, 'yyyy-MM-dd', 'en-US');
        return date > startDate && date < toDate;

      });
      this.userDetail.questions.forEach(element => {
        element.forEach(ele => {
          element.created_at = ele.created_at;
          if (ele.answer != "No") {
            this.yesCount++;
          }
        });
        if (this.yesCount >= 1) {
          element.is_symptomatic = "yes";
        }
        else {
          element.is_symptomatic = "No";
        }
      });


    }
  }

  onSubmit() {
    if(this.start_date && this.end_date){
      let project_id = localStorage.getItem("currentUser_projectId");
      let fromDate = formatDate(this.start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
      let toDate = formatDate(this.end_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
      console.log(environment.apiUrl + '/export_user_excel?project_id=' + project_id + '&user_id=' + this.userDetail.user_id + '&from=' + fromDate + '&to=' + toDate);
      window.open(environment.apiUrl + '/export_user_excel?project_id=' + project_id + '&user_id=' + this.userDetail.user_id + '&from=' + fromDate + '&to=' + toDate, "_self");
      this.start_date ="";
      this.end_date ="";
    }
    else{
      let project_id = localStorage.getItem("currentUser_projectId");
      console.log(environment.apiUrl + '/export_user_excel?project_id=' + project_id + '&user_id=' + this.userDetail.user_id + '&from=' + null + '&to=' + null);
      window.open(environment.apiUrl + '/export_user_excel?project_id=' + project_id + '&user_id=' + this.userDetail.user_id + '&from=' + null + '&to=' + null, "_self");
      this.start_date="";
      this.end_date="";
    }
  }


  openQuestionModel(question) {
    ($('#covidQueModal') as any).modal('show');
    this.question_ans = question;
    console.log(this.question_ans);
  }

}
