import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../profile/profile.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public name: string;
  public currentUserpic: any;
  public profile_pic: any;
  public initial;
  public initiallogo: any;
  user_roles: any;

  constructor(private router:Router,
              private profileservice:ProfileService) { }

  ngOnInit(): void {
    // debugger;
    this.name = localStorage.getItem('currentUserName');
    let username = this.name.split(" ");
    this.user_roles = localStorage.getItem('currentUserRoleId');
    this.initial = "";
    username.forEach(element => {
      this.initial += element.substring(0, 1).toUpperCase();
    });
    this.initiallogo = "https://via.placeholder.com/1000/ffffff/f84d0f&text=" + this.initial;
    console.log(this.initiallogo);
      // debugger;
    this.currentUserpic = localStorage.getItem('user_profile_pic');
    this.profile_pic = environment.profile_logo_url + this.currentUserpic;
    console.log(this.profile_pic);

    this.profileservice.changeduserName.subscribe(result => {
      this.name = result;
      let username = this.name.split(" ");
      this.initial = "";
      username.forEach(element => {
        this.initial += element.substring(0, 1).toUpperCase();
      });
      this.initiallogo = "https://via.placeholder.com/1000/ffffff/f84d0f&text=" + this.initial;
    })
    // debugger;
    this.profileservice.currentprofilepic.subscribe(result => {
      this.profile_pic = environment.profile_logo_url + result;
      console.log(this.profile_pic);
      
    })
  }

  logout(){
    localStorage.clear();
    this.router.navigate(['/login']);

  }
}
