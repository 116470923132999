import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TestCategoryService {

  constructor
    (
      private http: HttpClient,
    ) { }

  // for getting all employees details
  public getCategoryDetails()
  {
    return this.http.get<any>("http://localhost:8000/category")
    .pipe(map((res:any)=>{
      return res;
    }))
  }

  // for get by id
  public getEmployeeById(id:any)
  {
    return this.http.get<any>("http://localhost:8000/category/"+id)
  }

  // for adding employee details
  public addEmployeeDetail(data:any)
  {
    return this.http.post<any>("http://localhost:8000/category",data)
    .pipe(map((res:any)=>{
      return res;
    }))
  }

  // for update employee detail
  public updateEmployeeDetail(id:any,data:any)
  {
    return this.http.put<any>("http://localhost:8000/category/"+id,data)
    .pipe(map((res:any)=>
    {
      return res;
    }))
  }

  // for delete employee detail
  public deleteEmployeeDetail(id:any)
  {
    return this.http.delete<any>("http://localhost:8000/category/"+id)
    .pipe(map((res:any)=>
    {
      return res;
    }))
  }


}
