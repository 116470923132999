import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserdirectoryService {

  constructor(public http: HttpClient) { }
  
  getUserList(Obj) {
    return this.http.post<any>(environment.apiUrl + '/user_directory', Obj
    )
  }

  addUser(Obj) {
    return this.http.post<any>(environment.apiUrl + '/add_user', Obj
    )
  }


  getProjectList(Obj) {
    return this.http.post<any>(environment.apiUrl + '/get_project_list', Obj
    )
  }


  editUser(Obj) {
    return this.http.post<any>(environment.apiUrl + '/edit_use_directory', Obj);
  }
  
  deleteUser(Obj) {
    return this.http.post<any>(environment.apiUrl + '/delete_user_directory', Obj);
  }

  projectAssign(Obj) {
    return this.http.post<any>(environment.apiUrl + '/project_assigned', Obj
    )
  }

  changePasswordAPI(Obj) {
    return this.http.post<any>(environment.apiUrl + '/reset_password',Obj
    )
  }
}
