import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule, routing } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VgCoreModule } from 'videogular2/compiled/core';
import { VgControlsModule } from 'videogular2/compiled/controls';
import { ToastrModule } from 'ngx-toastr';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
// import { TooltipModule } from 'ng2-tooltip-directive';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectConfig } from '@ng-select/ng-select';
 import { ɵs } from '@ng-select/ng-select';

import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { HeaderComponent } from './pages/header/header.component';
import { FooterComponent } from './pages/footer/footer.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { UserdirectoryComponent } from './pages/userdirectory/userdirectory.component';
import { FormDetailsComponent } from './pages/form-details/form-details.component';
import { CompanyListComponent } from './pages/company-list/company-list.component';
import { VideoreportsComponent } from './pages/videoreports/videoreports.component';
import { SettingComponent } from './pages/setting/setting.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { AuthGuard } from './pages/auth-guard/auth.guard';
import { QuestionComponent } from './pages/question/question.component';
import { CategoryComponent } from './pages/category/category.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { CategoryReportsComponent } from './pages/category-reports/category-reports.component';
import { TestCategoryComponent } from './pages/test-category/test-category.component';
import { NgxDropzoneModule } from 'ngx-dropzone';


@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    HomeComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    ProfileComponent,
    UserdirectoryComponent,
    FormDetailsComponent,
    ReportsComponent,
    QuestionComponent,
    CompanyListComponent,
    VideoreportsComponent,
    SettingComponent,
    CategoryComponent,
    CategoryReportsComponent,
    TestCategoryComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSkeletonLoaderModule,
    BsDatepickerModule.forRoot(),
    // TooltipModule,
    AngularMyDatePickerModule,
    NgSelectModule,
    routing,
    NgMultiSelectDropDownModule.forRoot(),
    CommonModule,
    VgCoreModule,
    VgControlsModule,
    CKEditorModule,
    NgxDropzoneModule
  ],
  providers: [AuthGuard,NgSelectConfig, ɵs],
  bootstrap: [AppComponent]
})
export class AppModule { }
