import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(public http:HttpClient) { }

  login(user) {
    return this.http.post<any>(environment.apiUrl + '/login', {
      'email': user.email,
      'password': user.password
    });
  }
}
