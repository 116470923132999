import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  changeduserName: Subject<string> = new Subject();
  currentprofilepic: Subject<string> = new Subject();

  constructor(private http: HttpClient) { }


  changePasswordAPI(changePasswordFormdata) {
    return this.http.post<any>(environment.apiUrl + '/change_password', 
      {
        'newpass': changePasswordFormdata.newpass,
        'confirmpass': changePasswordFormdata.confirmpass,
        'currentpass': changePasswordFormdata.currentpass,
        'user_id': localStorage.getItem('currentUserId')
      }
    )
  }

  getUserProfile(Obj){
    return this.http.post<any>(environment.apiUrl + '/get_user_detail',Obj)
  }

  updateProfile(formData){
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }
    return this.http.post<any>(environment.apiUrl + '/update_user',formData)
  }
}
