import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(public http: HttpClient) { }

 
  public getCategoryDetails() {
    return this.http.get<any>(environment.apiUrl + '/get_category_list')
  }

  public getCategoryDetailsById(catIdObj:any) {
    return this.http.post<any>(environment.apiUrl + '/get_dy_question_list_by_cat_id',catIdObj)
  }

  public addCategoryDetails(addCategoryObj:any) {
    return this.http.post<any>(environment.apiUrl + '/add_dy_question', addCategoryObj)
  }
  
  public editCategoryDetails(editCategoryObj) {
    return this.http.post<any>(environment.apiUrl + '/edit_dy_question', editCategoryObj);
  }
  
  public deleteCategoryDetails(deleteCategoryObj) {
    return this.http.post<any>(environment.apiUrl + '/delete_category', deleteCategoryObj);
  }

  public setCategoryAsDefault(setCategoryObj) {
    return this.http.post<any>(environment.apiUrl + '/set_cat_as_default', setCategoryObj);
  }



}
