import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { QuestionService } from './question.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {

  public questionList: any;
  public selectedLanguage: any;
  public selectQuestionId: any;
  public addQuestionForm: FormGroup;
  public editQuestionForm: FormGroup;
  public selectedQuestion:any;
  // selectLanguage = [
  //   {lang_id:1, value:'Engilsh'},
  //   {lang_id:2, value:'Spanish'}
  // ];
public lang:any;

  constructor(private questionservice: QuestionService,
    private router: Router,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,) { }

  ngOnInit(): void {
    this.formValidation();
    this.getQuestionList();
    this.getLanguageList();
  }

  
  formValidation() {
    this.addQuestionForm = this.formBuilder.group({
      question: new FormControl('', [
        Validators.required
      ]),
      lang_id: new FormControl('', [
        Validators.required
      ]),
    });
    this.editQuestionForm = this.formBuilder.group({
      question: new FormControl('', [
        Validators.required
      ]),
      lang_id: new FormControl('', [
        Validators.required
      ]),
    });
  }



  getQuestionList() {
    this.questionservice.questionList().subscribe(res => {
      if (res.success == 1) {
        this.questionList = res.data;
      }
    })
  }

  getLanguageList(){
    this.questionservice.getLanguage().subscribe(res =>{
      if(res.success == 1){
        this.selectedLanguage = res.data;
        console.log(this.selectedLanguage);
        
      }
    })
  }

  addQuestionModal(){
    this.addQuestionForm.reset();
    ($('#addquestionModal')as any).modal('show');
  }

  saveQuestion(quesdata){
    let Obj={
      project_id: 0,
      lang_id:quesdata.lang_id,
      question:quesdata.question
    };
    this.questionservice.addQuestion(Obj).subscribe(res =>{
      if(res.success == 1){
        this.toastrService.success(res.message);
        ($('#addquestionModal')as any).modal('hide');
        this.getQuestionList();
      }
      else{
        this.toastrService.error(res.message);
        ($('#addquestionModal')as any).modal('hide');
       return false;
      }
    },error =>{
      ($('#addquestionModal')as any).modal('hide');
      return false;
    }
    )
  }


  updateQuestionModal(ques){
    this.selectedQuestion = ques;
    ($('#EditQuestionModal') as any).modal('show');
    console.log(ques);
    this.editQuestionForm.patchValue({
      'question': ques.question,
      'lang_id': ques.lang_id,
    })
    console.log(this.editQuestionForm);
  }

  updateQuestion(questions){
    console.log(questions);
    let Obj = {
      ques_id: this.selectedQuestion.ques_id,
      question: questions.question,
    }
    this.questionservice.updateQuestions(Obj).subscribe(res => {
      if (res.success == 1) {
        this.toastrService.success(res.message);
        ($('#EditQuestionModal') as any).modal('hide');
        this.getQuestionList();
      }
      else {
        this.toastrService.error(res.message);
        ($('#EditQuestionModal') as any).modal('hide');
        return false;
      }
    }, error => {
      ($('#EditQuestionModal') as any).modal('hide');
      return false;
    })
  }


  deleteQuestion(ques_id) {
    this.selectQuestionId = ques_id;
    ($('#deletequestion') as any).modal('show');
  }

  deleteQuestionConfirm() {
    let Obj = {
      'ques_id': this.selectQuestionId
    }
    this.questionservice.deleteQuestion(Obj).subscribe(res => {
      if (res.success == 1) {
        this.toastrService.success(res.message);
        ($('#deletequestion') as any).modal('hide');
        this.getQuestionList();
      }
      else {
        this.toastrService.error(res.message);
        ($('#deletequestion') as any).modal('hide');
        return false;
      }
    })
  }

  update(e){
    // this.selected = e.target.value
  }

  SearchProduct(){}

}
