// // for live domain
export const environment = {
  production: false,
  apiUrl: 'https://skiles.hsmproject.in/webapi/public/api',
  profile_logo_url: 'https://skiles.hsmproject.in/webapi/storage/app/public/user_photos/',
  loginSession: false,
};


// // for test domain
// export const environment = {
//   production: false,
//   apiUrl: 'https://testskiles.hsmproject.in/webapi/public/api',
//   profile_logo_url: 'https://testskiles.hsmproject.in/webapi/storage/app/public/user_photos/',
//   loginSession: false,
// };
