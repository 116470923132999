import { ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, AbstractControl, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CompanyListService } from '../company-list/company-list.service';
import { CategoryService } from '../category/category.service';
import Stepper from 'bs-stepper';
import Swal from 'sweetalert2';
import { TestCategoryService } from './test-category.service';
declare var CKEDITOR: any;
// declare const Stepper: any;

@Component({
	selector: 'app-test-category',
	templateUrl: './test-category.component.html',
	styleUrls: ['./test-category.component.css']
})
export class TestCategoryComponent implements OnInit {

	@ViewChild('categoryInput', { static: false }) categoryInput: ElementRef;
	@ViewChild("ckeditor", { static: false }) ckeditor: any;

	public loader = true;
	addCategoryForm: FormGroup;
	editCategoryForm: FormGroup;
	public addVideoForm: FormGroup;
	engQuestionAnsForm: FormGroup;
	spnQuestionAnsForm: FormGroup;
	public companyList: any;
	public categoryData: any;
	public selectedcompany: any;
	public selectedCompanyId: any;
	public selectQuesAns: boolean = false;
	public showQuesAns: boolean = false;
	public editShowQuesAns: boolean = false;
	public isButtonVisible: boolean = true;
	public selectedShowCategory: boolean = false;

	private stepper1!: Stepper;
	private stepper2!: Stepper;
	// activeStep = 0;
	activeTab: string = 'English';
	activeStep: number = 1;
	selectedShowCategoryCount: number = 0;

	// @ViewChild('stepper1', { static: true }) stepperEl: ElementRef;

	// private stepper!: Stepper;
	// private stepper: Stepper; 

	noteData: string;


	public editorConfig = {
		extraPlugins: "editorplaceholder, basicstyles, colorbutton, colordialog, easyimage, format, find, stylescombo, justify",
		editorplaceholder: "Enter your note here...",
		removeButtons: "",
		removePlugins: 'image',
		font_names: 'Arial/Arial, Helvetica, sans-serif; Times New Roman/Times New Roman, Times, serif; Verdana',
		font_size: '12px/12px; 14px/14px; 16px/16px; 18px/18px; 24px/24px; 28px/28px; 32px/32px',
		justifyClasses: ['text-left', 'text-center', 'text-right', 'text-justify'],
		toolbar: [
			{ name: 'copyFormatting', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord'] },
			{ name: 'clipboard', items: ['Undo', 'Redo'] },
			{ name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat'] },
			{ name: 'editing', items: ['Find', 'Replace'] },
			{ name: 'paragraph', items: ['BulletedList', 'NumberedList', 'Indent', 'Outdent', 'Blockquote'] },
			{ name: 'alignment', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
			{ name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
			{ name: 'colors', items: ['TextColor', 'BGColor'] },
			{ name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
			{ name: 'insert', items: ['Image', 'EasyImageUpload', 'Table', 'HorizontalRule', 'SpecialChar'] },
			{ name: 'tools', items: ['Maximize'] },
			{ name: 'others', items: ['-'] },
			{ name: 'document', items: ['SelectAll', 'Source', 'Preview'] },
		],
		height: 188,
		colorButton_enableMore: true,
		colorButton_colors: '000,00f,0f0,0ff,f00,ff0,fff',
		colorButton_foreStyle: {
			element: 'span',
			styles: { 'color': '#(color)' }
		},
		colorButton_backStyle: {
			element: 'span',
			styles: { 'background-color': '#(color)' }
		},
		easyimage_styles: {
			gradient1: {
				group: 'easyimage-gradients',
				attributes: {
					'class': 'easyimage-gradient-1'
				},
				label: 'Blue Gradient',
				icon: 'https://ckeditor.com/docs/ckeditor4/4.20.2/examples/assets/easyimage/icons/gradient1.png',
				iconHiDpi: 'https://ckeditor.com/docs/ckeditor4/4.20.2/examples/assets/easyimage/icons/hidpi/gradient1.png'
			},
			gradient2: {
				group: 'easyimage-gradients',
				attributes: {
					'class': 'easyimage-gradient-2'
				},
				label: 'Pink Gradient',
				icon: 'https://ckeditor.com/docs/ckeditor4/4.20.2/examples/assets/easyimage/icons/gradient2.png',
				iconHiDpi: 'https://ckeditor.com/docs/ckeditor4/4.20.2/examples/assets/easyimage/icons/hidpi/gradient2.png'
			},
			noGradient: {
				group: 'easyimage-gradients',
				attributes: {
					'class': 'easyimage-no-gradient'
				},
				label: 'No Gradient',
				icon: 'https://ckeditor.com/docs/ckeditor4/4.20.2/examples/assets/easyimage/icons/nogradient.png',
				iconHiDpi: 'https://ckeditor.com/docs/ckeditor4/4.20.2/examples/assets/easyimage/icons/hidpi/nogradient.png'
			},
		},
		easyimage_toolbar: [
			'EasyImageFull',
			'EasyImageSide',
			'EasyImageGradient1',
			'EasyImageGradient2',
			'EasyImageNoGradient',
			'EasyImageAlt'
		],
	};

	constructor(
		private formBuilder: FormBuilder,
		private fb: FormBuilder,
		private router: Router,
		private companylistser: CompanyListService,
		private toastrService: ToastrService,
		private CategoryService: TestCategoryService,
		private renderer: Renderer2,
		private cdr: ChangeDetectorRef
	) { }

	ngOnInit(): void {
		this.formValidation();
		this.noteData = '';
	}


	ngAfterViewInit() {
		let stepperElement = document.querySelector('#stepper1');
		if (stepperElement !== null) {
			this.stepper1 = new Stepper(stepperElement, {
				linear: false,
				animation: true
			})
		}
		let stepper2Element = document.querySelector('#stepper2');
		if (stepper2Element !== null) {
			this.stepper2 = new Stepper(stepper2Element, {
				linear: false,
				animation: true
			})
		}

	}

	public next($event: MouseEvent) {
		// if (this.engIndex.length < 0) {
		// 	console.log("this.engIndex.length", this.engIndex.length);
		// 	($event.target as HTMLButtonElement).disabled = false;
		// 	this.stepper1.next();
		// }
		// else {
		// 	this.stepper1.next();
		// 	($event.target as HTMLButtonElement).disabled = true;
		// }
		this.stepper1.next();
	}

	spanishFormVisited: boolean = false;
	public previous() {
		this.stepper1.previous();
	}

	public next1($event: MouseEvent) {
		this.stepper2.next();
	}

	public previous1() {
		this.stepper2.previous();
	}

	public formValidation() {
		this.addCategoryForm = this.formBuilder.group({
			cat_name: new FormControl('', [Validators.required]),
			cat_description: new FormControl('', [Validators.required]),
			showQusAns: new FormControl(""),
			cat_note: new FormControl("", [Validators.required]),
		});
		this.editCategoryForm = this.formBuilder.group({
			cat_name: new FormControl('', [Validators.required]),
			cat_description: new FormControl('', [Validators.required]),
			cat_note: new FormControl(""),
			showQusAns: new FormControl(""),
		});


	}


	toggledItemsCount: number = 0;
	limitExceeded: boolean = false;

	public addCategoryFormModal() {
		// this.stepper1.reset();
		this.ckeditor.instance.setData('');
		this.selectQuesAns = false;
		this.showQuesAns = false;
		this.editShowQuesAns = false;
		this.addCategoryForm.reset();
		($('#addCategoryFormModal') as any).modal('show');
	}

	public onChange(event: any) {
		console.log("add=>>>>>>onChanhe->event", event.target.checked);
		if (event.target.checked == true) {
			this.showQuesAns = true;
		}
		else {
			this.showQuesAns = false;
		}
	}

	public saveCategory(addCategoryFormValue: any) {
		let addCatObj = {
			cat_name: addCategoryFormValue.cat_name,
			cat_description: addCategoryFormValue.cat_description,
			cat_note: addCategoryFormValue.cat_note,
			showQusAns: this.selectQuesAns,
			quesAnsEng: this.engQuestionAnsForm.value.engQuestions,
			quesAnsSpn: this.spnQuestionAnsForm.value.spnQuestions,
		};
		console.log("add cat obj=>", addCatObj);
	}

}
