import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from "@angular/forms";
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';
import { IAngularMyDpOptions } from 'angular-mydatepicker';

@Component({
  selector: 'app-videoreports',
  templateUrl: './videoreports.component.html',
  styleUrls: ['./videoreports.component.scss']
})
export class VideoreportsComponent implements OnInit {

  public videoDetail = JSON.parse(localStorage.getItem('videoDetails'));
  public Question = JSON.parse(localStorage.getItem('videoQuestionList'));
  public originalVideoDetails: any;
  public yesCount = 0;
  public videodateForm: FormGroup;
  public question_ans: any = [];
  public project_name = localStorage.getItem('currentUser_projectName');
  public start_date: any;
  public end_date: any;

  constructor(private fromBuilder: FormBuilder) { }

  ngOnInit(): void {
    console.log(this.Question);
    console.log(this.videoDetail);
    this.formValidation();
    this.videoDetail.questions.forEach(element => {
      element.forEach(ele => {
        element.created_at = ele.created_at;
        if (ele.answer != "No") {
          this.yesCount++;
        }
      });
      if (this.yesCount >= 1) {
        element.is_symptomatic = "yes";
      }
      else {
        element.is_symptomatic = "No";
      }
    });
    this.originalVideoDetails = JSON.parse(JSON.stringify(this.videoDetail));
  }

  formValidation() {
    this.videodateForm = this.fromBuilder.group({
      'video_start_date': ['', [Validators.required]],
      'video_end_date': ['', [Validators.required]]
    })
  }
  


  public myDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: 'dd-mm-yyyy',
  };
  public endDateOptions: IAngularMyDpOptions = {
    dateFormat: 'dd-mm-yyyy',
  };

  onStartDateChange() {
    if (this.start_date != null) {
      const startDate = formatDate(this.start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
      this.endDateOptions = {
        ...this.endDateOptions,
        disableUntil: {
          year: this.start_date.singleDate.date.year,
          month: this.start_date.singleDate.date.month,
          day: this.start_date.singleDate.date.day - 1
        }
      };
      // If end date is before start date, reset end date
      if (this.end_date && this.end_date.singleDate.jsDate < this.start_date.singleDate.jsDate) {
        this.end_date = null;
      }
    }
    // Apply filter when both start and end dates are selected
    if (this.start_date != null && this.end_date != null) {
      let startDate = formatDate(this.start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
      let toDate = formatDate(this.end_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
      this.videoDetail.questions = this.originalVideoDetails.questions.filter(item => {

        let date = formatDate(item[0].created_at, 'yyyy-MM-dd', 'en-US');
        return date > startDate && date < toDate;

      });
      this.videoDetail.questions.forEach(element => {
        element.forEach(ele => {
          element.created_at = ele.created_at;
          if (ele.answer != "No") {
            this.yesCount++;
          }
        });
        if (this.yesCount >= 1) {
          element.is_symptomatic = "yes";
        }
        else {
          element.is_symptomatic = "No";
        }
      });

    }
  }


  onVideoFilter() {
    if (this.start_date != null && this.end_date != null) {
      let startDate = formatDate(this.start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
      let toDate = formatDate(this.end_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
      this.videoDetail.questions = this.originalVideoDetails.questions.filter(item => {

        let date = formatDate(item[0].created_at, 'yyyy-MM-dd', 'en-US');
        return date > startDate && date < toDate;

      });
      this.videoDetail.questions.forEach(element => {
        element.forEach(ele => {
          element.created_at = ele.created_at;
          if (ele.answer != "No") {
            this.yesCount++;
          }
        });
        if (this.yesCount >= 1) {
          element.is_symptomatic = "yes";
        }
        else {
          element.is_symptomatic = "No";
        }
      });

    }
    // this.yesCount=0;
    // let fromDate = formatDate(value.video_start_date, 'yyyy-MM-dd', 'en-US');
    // let toDate = formatDate(value.video_end_date, 'yyyy-MM-dd', 'en-US');
    // console.log(fromDate,toDate);
    // console.log(this.videoDetail);
    // this.videoDetail.questions = this.originalVideoDetails.questions.filter(item => { 
    //       let date=formatDate(item[0].created_at, 'yyyy-MM-dd', 'en-US');
    //       console.log(date);
    //       return date >= fromDate && date <= toDate;
    // });
    // this.videoDetail.questions.forEach(element => {
    //   element.forEach(ele => {
    //     element.created_at=ele.created_at;
    //     if(ele.answer != "No"){
    //       this.yesCount++;
    //     }
    //   });
    // if(this.yesCount>=1){
    //   element.is_symptomatic="yes";
    // }
    // else{
    //   element.is_symptomatic="No";
    // }
    // });

  }

  onVideoSubmit() {
    console.log("Start Date->>",this.start_date);
    console.log("End Date->>",this.end_date);
    
    if (this.start_date && this.end_date) {
      let project_id = localStorage.getItem("currentUser_projectId");
      let fromDate = formatDate(this.start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
      let toDate = formatDate(this.end_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
      console.log(environment.apiUrl + '/video_user_excel?project_id=' + project_id + '&user_id=' + this.videoDetail.user_id + '&from=' + fromDate + '&to=' + toDate);
      window.open(environment.apiUrl + '/video_user_excel?project_id=' + project_id + '&user_id=' + this.videoDetail.user_id + '&from=' + fromDate + '&to=' + toDate, "_self")
      this.start_date ="";
      this.end_date ="";
    }
    else {
      let project_id = localStorage.getItem("currentUser_projectId");
      console.log(environment.apiUrl + '/video_user_excel?project_id=' + project_id + '&user_id=' + this.videoDetail.user_id + '&from=' + null + '&to=' + null);
      window.open(environment.apiUrl + '/video_user_excel?project_id=' + project_id + '&user_id=' + this.videoDetail.user_id + '&from=' + null + '&to=' + null, "_self");
      this.start_date ="";
      this.end_date ="";
    }
  }

  openQuestionModel(questions) {
    ($('#videoQueModal') as any).modal('show');
    this.question_ans = questions;
    console.log(this.question_ans);

  }

}
