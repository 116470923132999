import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyListService {

  constructor(public http: HttpClient) { }

  companyList() {
    return this.http.get<any>(environment.apiUrl + '/get_companies')
  }

  addCompany(Obj) {
    return this.http.post<any>(environment.apiUrl + '/add_company', Obj
    )
  }
  
  editCompany(Obj) {
    return this.http.post<any>(environment.apiUrl + '/edit_company', Obj);
  }
  
  deleteCompany(Obj) {
    return this.http.post<any>(environment.apiUrl + '/delete_company', Obj);
  }

}
