import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  public http1:any;
  constructor(private http: HttpClient,handler: HttpBackend) {this.http1 = new HttpClient(handler); }

  getProjectList(Obj) {
    return this.http.post<any>(environment.apiUrl + '/get_project_list', Obj
    )
  }

  public getCategotyDetails() {
    return this.http.get<any>(environment.apiUrl + '/get_category_list')
  }


  addNewProject(Obj) {
    return this.http.post<any>(environment.apiUrl + '/add_project', Obj
    )
  }


  updateProject(Obj) {
    return this.http.post<any>(environment.apiUrl + '/edit_project', Obj
    )
  }

  deleteProject(Obj){
    return this.http.post<any>(environment.apiUrl + '/delete_project', Obj
    )
  }

  // downloadPDF(project_id) {
  //   return this.http.get<any>(environment.apiUrl + '/project_pdf?project_id=' + project_id)
  // }

  // uploadVideo(Obj){
  //   return this.http.post<any>(environment.apiUrl + '/video_uploder', Obj
  //   )
  // }

  uploadVideo(Obj){
   
    // let head=new HttpHeaders();
    // const authToken="Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImFiMTViNzExMGNiNWVlNDBiYzg2NTUxY2ZhMTM3M2M5MWI2Mzg2YWU5NDMzNzhmZjY4YjJlNmU2MDMzNmRmMmVmN2I4NzY2ZDczZWQ2YzcxIn0.eyJhdWQiOiI2IiwianRpIjoiYWIxNWI3MTEwY2I1ZWU0MGJjODY1NTFjZmExMzczYzkxYjYzODZhZTk0MzM3OGZmNjhiMmU2ZTYwMzM2ZGYyZWY3Yjg3NjZkNzNlZDZjNzEiLCJpYXQiOjE0ODIwNzkxODMsIm5iZiI6MTQ4MjA3OTE4MywiZXhwIjoyMTEzMjMxMTgzLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.lER9Ijq282lYPN-sUrCVumKftrbCWY5KCAtCAjInSbGNnGFr9L6xlvDlc1eRDwfbmvyeGIT38O-7OWyl5x1FlIObzFYKCHs11CLjteu31BksrY6w1NEwGxzCLdKkBBnrIkc_yL5_rFVVsap89GDoFV-uZgasjGCR6kSJGiwe2yOe9kI0USfkGDsRiVuSer9l5Qum3WeyEIdJGxvznwPmOkKrxxbbik-EUyICwUKde98eEkCUiiTuZHzsrWCadQmsUQDrzFul0PEYxPhg8A287QxH38qpkwouG4fEmzLCi0Lo0wS2Fn151kaGx188jAhh3JN0TaY1SFpEUiMx8THbK6mNeV-RR70di_rVutRvkDOLBhCM2cNpCbae4JzSnbL3WtC8HrU-HDO9lCLd5vwHDiPp_8iiE0m4CF3wVV1KYmy2KI5_l5hNQ88JUXbBaBEl2uHO6o4Kss3n5pt4vd9e2wGRKbEKK6O41q1WBBksZuOLfQ7ogxxTPbEMLa_7s1zz8IOMcQuwwzJl1y55ZlCBE3EL5GQ45Gh3w_mneX_80HgkWeO47ScHpKBVjcqFYYeYF75p4JnYZ8ZDaCF2nxHsNSHNu7tUx4U7VJVyPHsIer9LxDUgv2OfrrDZ5832kJQsHBWL60r25orYwO917txu0U9NNMYsw4Q9qux7t0JCoDo";
 
    // head.append('Authorization',authToken);
    // head.append('Content-Type','multipart/form-data');
    // head.append('Application','application/json');
     return this.http.post<any>(environment.apiUrl + '/video_uploder',Obj, {
       reportProgress: true,
       observe: 'events',
      //  headers : head
     });
  } 

  deleteVideo(Obj){
    return this.http.post<any>(environment.apiUrl + '/video_delete', Obj
    )
  }

  getAssignCompnayList(Obj) {
    return this.http.post<any>(environment.apiUrl + '/company_list_with_assignee', Obj)
  }
  
  addAssignCompanyData(Obj)
  {
    return this.http.post<any>(environment.apiUrl + '/assign_company', Obj)
  }

}
