import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from './profile.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmedValidator } from '../../confirmed.validator';
import { environment } from 'src/environments/environment';
import { CustomValidators } from 'ng2-validation';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  public editProfileForm: FormGroup;
  public userName: string;
  public userDetails: any;
  public changePasswordDetails;
  public changePasswordForm: FormGroup;
  public profile_picc: any;
  public initial;
  public initiallogo;
  public loader: boolean = true;
  imgLoad: boolean = false;


  constructor(private router: Router,
    private formbuilder: FormBuilder,
    private profileService: ProfileService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.formValidation();
    this.getProfileDetails();

  }

  formValidation() {
    this.editProfileForm = this.formbuilder.group({
      name: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [
        Validators.compose([Validators.required, CustomValidators.email])
      ]),
      designation: new FormControl('', [
        Validators.required
      ]),
    })
    this.changePasswordForm = this.formbuilder.group({
      'currentpass': ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      'newpass': ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      'confirmpass': ['', Validators.compose([Validators.required, Validators.minLength(6)])]
    },
      {
        validator: ConfirmedValidator('newpass', 'confirmpass')
      });
  }

  newpass(formGroup: FormGroup) {
    const { value: newpass } = formGroup.get('newpass');
    const { value: confirmpass } = formGroup.get('confirmpass');
    return newpass === confirmpass ? null : { passwordNotMatch: true };
  }

  openEditProfile(data: any) {
    ($('#EditModal') as any).modal('show');
    this.editProfileForm.patchValue({
      'name': data.name,
      'email': data.email,
      'designation': data.designation,
      // 'profile_pic':data.initiallogo
    })
    console.log(data);

  }

  getProfileDetails() {
    let Obj = {
      'user_id': localStorage.getItem('currentUserId')
    }
    this.profileService.getUserProfile(Obj).subscribe(res => {
      if (res.success == 1) {
        this.userDetails = res.data;
        this.loader = false;
        this.imgLoad = true;
        this.profile_picc = environment.profile_logo_url + this.userDetails.profile_pic;
        console.log(this.profile_picc);

        let username = this.userDetails.name.split(" ");
        console.log(username);
        this.initial = "";
        username.forEach(element => {
          this.initial += element.substring(0, 1).toUpperCase();
        });
        this.initiallogo = "https://via.placeholder.com/1000/ffffff/f84d0f&text=" + this.initial;
        // this.initiallogo = this.initial;

        // this.profile_picc = environment.profile_logo_url + this.userDetails.profile_pic;
        // console.log(this.initiallogo, this.profile_picc);

        localStorage.setItem('currentUserName', this.userDetails.name);
        this.profileService.changeduserName.next(localStorage.getItem('currentUserName'));

        localStorage.setItem('user_profile_pic', this.userDetails.profile_pic);
        this.profileService.currentprofilepic.next(localStorage.getItem('user_profile_pic'));

      }
    })
  }

  updateProfile(profiledetail) {
    // let Obj = {
    //   'user_id': localStorage.getItem('currentUserId'),
    //   'name': profiledetail.name,
    //   'email': profiledetail.email,
    //   'designation': profiledetail.designation
    // }
    let formData = new FormData();
    formData.append('user_id', localStorage.getItem('currentUserId'));
    formData.append('name', profiledetail.name);
    formData.append('email', profiledetail.email);
    formData.append('designation', profiledetail.designation);
    formData.append('profile_pic', this.upload_profile_pic);
    console.log(formData);

    this.profileService.updateProfile(formData).subscribe(res => {
      if (res.success == 1) {
        this.toastrService.success(res.message);
        ($('#EditModal') as any).modal('hide');
        this.getProfileDetails();
      }
      else {
        this.toastrService.error(res.message);
        ($('#EditModal') as any).modal('hide');
        return false;
      }
    }, error => {
      ($('#EditModal') as any).modal('hide');
      return false;
    }
    )
  }


  public onChangePassFormSubmit(values: Object): void {
    console.log(this.changePasswordForm.value);
    if (this.changePasswordForm.valid) {
      this.changePasswordAPI(this.changePasswordForm.value);
    }
  }


  changePasswordAPI(changePasswordFormdata) {
    console.log(changePasswordFormdata);

    this.profileService.changePasswordAPI(changePasswordFormdata).subscribe(res => {
      if (res.success == 1) {
        this.changePasswordDetails = res.data;
        console.log(changePasswordFormdata);
        this.toastrService.success(res.message);
        this.router.navigate(["/"]);
      } else {
        this.toastrService.error(res.message);
      }

    })
  }


  clearForm() {
    this.changePasswordForm.reset();
  }

  public upload_profile_pic;

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.upload_profile_pic = event.target.files[0];
      this.userDetails.profile_pic = this.upload_profile_pic.name;

      reader.readAsDataURL(file);

      reader.onload = () => {

        this.profile_picc = reader.result as string;

        console.log(this.upload_profile_pic, this.profile_picc);
      };

    }
  }

  oldPasswordText: boolean;
  toggleOldPasswordType() {
    this.oldPasswordText = !this.oldPasswordText;
  }

  
  newPasswordText: boolean;
  toggleNewPasswordType() {
    this.newPasswordText = !this.newPasswordText;
  }


  confirmPasswordText: boolean;
  toggleConfirmPasswordType() {
    this.confirmPasswordText = !this.confirmPasswordText;
  }



}
