import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(private http: HttpClient) { }

  questionList() {
    return this.http.get<any>(environment.apiUrl + '/get_question_list')
  }

  getLanguage() {
    return this.http.get<any>(environment.apiUrl + '/sel_language')
  }

  addQuestion(Obj) {
    return this.http.post<any>(environment.apiUrl + '/add_question', Obj)
  }

  updateQuestions(Obj) {
    return this.http.post<any>(environment.apiUrl + '/edit_question', Obj)
  }

  deleteQuestion(Obj) {
    return this.http.post<any>(environment.apiUrl + '/delete_question', Obj)
  }
}
