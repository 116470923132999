import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { SettingService } from './setting.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {

  public videoData: any;
  public video_url:any;
  public fileArray_orientation:any = [];
  public fileArray_icra:any = [];
  public uploadLoader_orientation:any = [];
  public uploadLoader_icra:any = [];
  public sizeError:any=[];

  constructor(private settingservice: SettingService,
    private router: Router,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,) { }

  ngOnInit(): void {
    this.getVideoList();
  }

  public orientation_video:any=[];
  public icra_video:any=[];
  

  getVideoList(){
    this.orientation_video = [];
    this.icra_video = [];
    this.settingservice.videoList().subscribe(res => {
      if (res.success == 1) {
        this.videoData = res.data;
        this.videoData.forEach((element, index) => {
          if(index <= 1){
            this.orientation_video.push(element)
          }
          else{
            this.icra_video.push(element)
          }
        });
        console.log(this.orientation_video);
        console.log(this.icra_video);      
      }      
    })
  }

  public upload_video:any;

  // upload(e, index, form) {
  //   let file = e.target.files;
  //   if(file[0].size <= 314572800){
  //     this.sizeError[index]=false;
  //     this.videos = form.get('videos') as FormArray;
  //     this.videos.controls[index].patchValue({
  //       'uploadvideo': file
  //     })
  //   }
  //   else{
  //     this.sizeError[index]=true;
  //   }
    
  // }


  onSelectFile(event,index,type) {
    const reader = new FileReader();   
    if(event.target.files && event.target.files.length ) {    
      if(type == 'orientation'){
        this.fileArray_orientation[index] = event.target.files;
      }else{
        this.fileArray_icra[index] = event.target.files;     
      }    
      const [file] = event.target.files;
      this.upload_video = event.target.files[0];
      this.video_url = this.upload_video;    
     console.log(this.video_url);   
    }
  }
  
  uploadVideos(data,index,type){
    if(type == 'orientation'){
      this.uploadLoader_orientation[index] = true;
    }else{
      this.uploadLoader_icra[index] = true;
    } 
    let formData = new FormData();
    formData.append('user_id', localStorage.getItem('currentUserId'));
    formData.append('uploadvideo', this.upload_video); 
    formData.append('video_id', data.video_id); 
    formData.append('lang', data.lang_id);  
    this.settingservice.updateVideo(formData).subscribe(res => {
      if (res.success == 1) {  
        this.toastrService.success(res.message);
        this.fileArray_orientation[index] =null ;
        this.fileArray_icra[index] = null;    
        this.uploadLoader_orientation[index] = false; 
        this.uploadLoader_icra[index] = false;     
        this.getVideoList();
      }
      else {
        this.toastrService.error(res.message);
        this.fileArray_orientation[index] =null ;
        this.fileArray_icra[index] = null;    
        this.uploadLoader_orientation[index] = false; 
        this.uploadLoader_icra[index] = false;  
      }
    }, error => {
      this.fileArray_orientation[index] =null ;
        this.fileArray_icra[index] = null;     
        this.uploadLoader_orientation[index] = false; 
        this.uploadLoader_icra[index] = false;   
    }
    )

  }

  

}
