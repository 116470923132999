import { ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, AbstractControl, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CompanyListService } from '../company-list/company-list.service';
import { CategoryService } from './category.service';
import Stepper from 'bs-stepper';
import Swal from 'sweetalert2';
declare var CKEDITOR: any;
// declare const Stepper: any;

@Component({
	selector: 'app-category',
	templateUrl: './category.component.html',
	// styleUrls: ['./category.component.css']
	styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

	@ViewChild('categoryInput', { static: false }) categoryInput: ElementRef;
	@ViewChild("ckeditor", { static: false }) ckeditor: any;

	public loader = true;
	addCategoryForm: FormGroup;
	editCategoryForm: FormGroup;
	public addVideoForm: FormGroup;
	engQuestionAnsForm: FormGroup;
	spnQuestionAnsForm: FormGroup;
	public companyList: any;
	public categoryData: any;
	public selectedcompany: any;
	public selectedCompanyId: any;
	public selectQuesAns: boolean = false;
	public showQuesAns: boolean = false;
	public editShowQuesAns: boolean = false;
	public isButtonVisible: boolean = true;
	public selectedShowCategory: boolean = false;

	private stepper1!: Stepper;
	private stepper2!: Stepper;
	// activeStep = 0;
	activeTab: string = 'English';
	activeStep: number = 1;
	selectedShowCategoryCount: number = 0;

	// @ViewChild('stepper1', { static: true }) stepperEl: ElementRef;

	// private stepper!: Stepper;
	// private stepper: Stepper; 

	noteData: string;


	public editorConfig = {
		extraPlugins: "editorplaceholder, basicstyles, colorbutton, colordialog, easyimage, format, find, stylescombo, justify",
		editorplaceholder: "Enter your note here...",
		removeButtons: "",
		removePlugins: 'image',
		font_names: 'Arial/Arial, Helvetica, sans-serif; Times New Roman/Times New Roman, Times, serif; Verdana',
		font_size: '12px/12px; 14px/14px; 16px/16px; 18px/18px; 24px/24px; 28px/28px; 32px/32px',
		justifyClasses: ['text-left', 'text-center', 'text-right', 'text-justify'],
		toolbar: [
			{ name: 'copyFormatting', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord'] },
			{ name: 'clipboard', items: ['Undo', 'Redo'] },
			{ name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat'] },
			{ name: 'editing', items: ['Find', 'Replace'] },
			{ name: 'paragraph', items: ['BulletedList', 'NumberedList', 'Indent', 'Outdent', 'Blockquote'] },
			{ name: 'alignment', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
			{ name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
			{ name: 'colors', items: ['TextColor', 'BGColor'] },
			{ name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
			{ name: 'insert', items: ['Image', 'EasyImageUpload', 'Table', 'HorizontalRule', 'SpecialChar'] },
			{ name: 'tools', items: ['Maximize'] },
			{ name: 'others', items: ['-'] },
			{ name: 'document', items: ['SelectAll', 'Source', 'Preview'] },
		],
		height: 188,
		colorButton_enableMore: true,
		colorButton_colors: '000,00f,0f0,0ff,f00,ff0,fff',
		colorButton_foreStyle: {
			element: 'span',
			styles: { 'color': '#(color)' }
		},
		colorButton_backStyle: {
			element: 'span',
			styles: { 'background-color': '#(color)' }
		},
		easyimage_styles: {
			gradient1: {
				group: 'easyimage-gradients',
				attributes: {
					'class': 'easyimage-gradient-1'
				},
				label: 'Blue Gradient',
				icon: 'https://ckeditor.com/docs/ckeditor4/4.20.2/examples/assets/easyimage/icons/gradient1.png',
				iconHiDpi: 'https://ckeditor.com/docs/ckeditor4/4.20.2/examples/assets/easyimage/icons/hidpi/gradient1.png'
			},
			gradient2: {
				group: 'easyimage-gradients',
				attributes: {
					'class': 'easyimage-gradient-2'
				},
				label: 'Pink Gradient',
				icon: 'https://ckeditor.com/docs/ckeditor4/4.20.2/examples/assets/easyimage/icons/gradient2.png',
				iconHiDpi: 'https://ckeditor.com/docs/ckeditor4/4.20.2/examples/assets/easyimage/icons/hidpi/gradient2.png'
			},
			noGradient: {
				group: 'easyimage-gradients',
				attributes: {
					'class': 'easyimage-no-gradient'
				},
				label: 'No Gradient',
				icon: 'https://ckeditor.com/docs/ckeditor4/4.20.2/examples/assets/easyimage/icons/nogradient.png',
				iconHiDpi: 'https://ckeditor.com/docs/ckeditor4/4.20.2/examples/assets/easyimage/icons/hidpi/nogradient.png'
			},
		},
		easyimage_toolbar: [
			'EasyImageFull',
			'EasyImageSide',
			'EasyImageGradient1',
			'EasyImageGradient2',
			'EasyImageNoGradient',
			'EasyImageAlt'
		],
	};

	constructor(
		private formBuilder: FormBuilder,
		private fb: FormBuilder,
		private router: Router,
		private companylistser: CompanyListService,
		private toastrService: ToastrService,
		private CategoryService: CategoryService,
		private renderer: Renderer2,
		private cdr: ChangeDetectorRef
	) { }

	ngOnInit(): void {
		this.formValidation();
		this.getCategoryLists();
		this.noteData = '';
	}


	ngAfterViewInit() {
		let stepperElement = document.querySelector('#stepper1');
		if (stepperElement !== null) {
			this.stepper1 = new Stepper(stepperElement, {
				linear: false,
				animation: true
			})
		}
		let stepper2Element = document.querySelector('#stepper2');
		if (stepper2Element !== null) {
			this.stepper2 = new Stepper(stepper2Element, {
				linear: false,
				animation: true
			})
		}

	}

	public next($event: MouseEvent) {
		// if (this.engIndex.length < 0) {
		// 	console.log("this.engIndex.length", this.engIndex.length);
		// 	($event.target as HTMLButtonElement).disabled = false;
		// 	this.stepper1.next();
		// }
		// else {
		// 	this.stepper1.next();
		// 	($event.target as HTMLButtonElement).disabled = true;
		// }
		this.stepper1.next();
	}

	spanishFormVisited: boolean = false;
	public previous() {
		this.stepper1.previous();
	}

	public next1($event: MouseEvent) {
		console.log("this.engIndex.length", this.engIndex.length);
		this.stepper2.next();
	}

	public previous1() {
		this.stepper2.previous();
	}

	public formValidation() {
		this.addCategoryForm = this.formBuilder.group({
			cat_name: new FormControl('', [Validators.required]),
			cat_description: new FormControl('', [Validators.required]),
			showQusAns: new FormControl(""),
			cat_note: new FormControl("", [Validators.required]),
		});
		this.editCategoryForm = this.formBuilder.group({
			cat_name: new FormControl('', [Validators.required]),
			cat_description: new FormControl('', [Validators.required]),
			cat_note: new FormControl(""),
			showQusAns: new FormControl(""),
		});
		this.engQuestionAnsForm = this.formBuilder.group({
			engQuestions: this.formBuilder.array([this.initEngQuestion('1')])
		});
		this.spnQuestionAnsForm = this.formBuilder.group({
			spnQuestions: this.formBuilder.array([this.initSpnQuestion('1')])
		});
	}

	public getCategoryLists() {
		this.CategoryService.getCategoryDetails().subscribe(
			{
				next: (res: any) => {
					if (res.success == 1) {
						this.categoryData = res.data;
						console.log("this.categoryData=>", this.categoryData);
						this.loader = false;

						this.categoryData.forEach(category => {
							category.checked = category.is_default === 1;
						});

						this.toggledItemsCount = this.categoryData.filter(category => category.is_default === 1).length;
					}
					else {
						this.loader = true;
						if (!this.categoryData || this.categoryData.length === 0) {
							$('.loadingContent1').html('No category found.');
						}
					}
				},
				error: (error: any) => { }
			});
	}

	toggledItemsCount: number = 0;
	limitExceeded: boolean = false;
	// checkselection(event: any, index: number, category: any) {
	// 	if (event.target.checked) {
	// 		console.log("Checkbox is checked");
	// 		// if (this.toggledItemsCount < 3) {
	// 		if (this.toggledItemsCount < 3 || category.is_default === 1) {
	// 			this.toggledItemsCount++;
	// 			this.limitExceeded = false;
	// 			console.log("in checked cat_id=>>>>", category.id);

	// 			let asCatObj =
	// 			{
	// 				'cat_id': category.id,
	// 				'is_default': 1,
	// 			}
	// 			console.log("asCatObj", asCatObj);
	// 			this.CategoryService.setCategoryAsDefault(asCatObj).subscribe(
	// 				{
	// 					next: (res: any) => {
	// 						if (res.success == 1) {
	// 							this.toastrService.success(res.message);
	// 							this.getCategoryLists();
	// 						}
	// 						else {
	// 							this.toastrService.error(res.message);
	// 						}
	// 					},
	// 					error: (error: any) => { }
	// 				});


	// 		} else {
	// 			event.preventDefault();
	// 			event.target.checked = false;
	// 			Swal.fire({
	// 				html: "Your limit has been exceeded!",
	// 				icon: 'warning',
	// 				showConfirmButton: false,
	// 				timer: 5000
	// 			});
	// 		}
	// 	} else {
	// 		console.log("Checkbox is unchecked");
	// 		this.toggledItemsCount--;
	// 		this.limitExceeded = false;
	// 		console.log("in unchecked cat_id=>>>>", category.id);

	// 		let asCatObj =
	// 		{
	// 			'cat_id': category.id,
	// 			'is_default': 0,
	// 		}
	// 		console.log("asCatObj", asCatObj);
	// 		this.CategoryService.setCategoryAsDefault(asCatObj).subscribe(
	// 			{
	// 				next: (res: any) => {
	// 					if (res.success == 1) {
	// 						this.toastrService.success(res.message);
	// 						this.getCategoryLists();
	// 					}
	// 					else {
	// 						this.toastrService.error(res.message);
	// 					}
	// 				},
	// 				error: (error: any) => { }
	// 			});


	// 	}
	// }

	checkselection(event: any, index: number, category: any) {
		if (event.target.checked) {
			console.log("Checkbox is checked");
			if (this.toggledItemsCount < 3 || category.is_default === 1) {
				this.toggledItemsCount++;
				this.limitExceeded = false;
				console.log("in checked cat_id=>>>>", category.id);
				let asCatObj = {
					'cat_id': category.id,
					'is_default': 1,
				}
				console.log("asCatObj", asCatObj);
				this.CategoryService.setCategoryAsDefault(asCatObj).subscribe({
					next: (res: any) => {
						if (res.success == 1) {
							this.toastrService.success(res.message);
							this.getCategoryLists();
						} else {
							this.toastrService.error(res.message);
						}
					},
					error: (error: any) => { }
				});
			} else {
				event.preventDefault();
				event.target.checked = false;
				Swal.fire({
					html: "Your limit has been exceeded!",
					icon: 'warning',
					showConfirmButton: false,
					timer: 5000
				});
			}
		} else {
			console.log("Checkbox is unchecked");
			// Prevent turning off the last category with is_default set to 1
			if (this.toggledItemsCount > 1 || category.is_default === 0) {
				this.toggledItemsCount--;
				this.limitExceeded = false;
				console.log("in unchecked cat_id=>>>>", category.id);
				let asCatObj = {
					'cat_id': category.id,
					'is_default': 0,
				}
				console.log("asCatObj", asCatObj);
				this.CategoryService.setCategoryAsDefault(asCatObj).subscribe({
					next: (res: any) => {
						if (res.success == 1) {
							this.toastrService.success(res.message);
							this.getCategoryLists();
						} else {
							this.toastrService.error(res.message);
						}
					},
					error: (error: any) => { }
				});
			} else {
				event.preventDefault();
				event.target.checked = true;
				Swal.fire({
					html: "At least one category must be selected as default!",
					icon: 'warning',
					showConfirmButton: false,
					timer: 5000
				});
			}
		}
	}



	public addCategoryFormModal() {
		this.stepper1.reset();
		this.ckeditor.instance.setData('');
		this.selectQuesAns = false;
		this.showQuesAns = false;
		this.editShowQuesAns = false;
		this.addCategoryForm.reset();

		let engQuestionsArray = this.engQuestionAnsForm.get('engQuestions') as FormArray;
		while (engQuestionsArray.length !== 0) {
			engQuestionsArray.removeAt(0);
		}

		let spnQuestionsArray = this.spnQuestionAnsForm.get('spnQuestions') as FormArray;
		while (spnQuestionsArray.length !== 0) {
			spnQuestionsArray.removeAt(0);
		}



		// this.showQuesAns = falses;

		// const questionsArray = this.engQuestionAnsForm.get('questions') as FormArray;
		// questionsArray.controls = [];
		// questionsArray.setValue([]);

		// const newQuestionsArray = this.formBuilder.array([]);
		// this.engQuestionAnsForm.setControl('questions', newQuestionsArray);


		($('#addCategoryFormModal') as any).modal('show');
	}

	public onChange(event: any) {
		console.log("add=>>>>>>onChanhe->event", event.target.checked);
		if (event.target.checked == true) {
			this.showQuesAns = true;
		}
		else {
			this.showQuesAns = false;
		}
	}

	public saveCategory(addCategoryFormValue: any) {
		let addCatObj = {
			cat_name: addCategoryFormValue.cat_name,
			cat_description: addCategoryFormValue.cat_description,
			cat_note: addCategoryFormValue.cat_note,
			showQusAns: this.selectQuesAns,
			quesAnsEng: this.engQuestionAnsForm.value.engQuestions,
			quesAnsSpn: this.spnQuestionAnsForm.value.spnQuestions,
		};
		console.log("add cat obj=>", addCatObj);
		this.CategoryService.addCategoryDetails(addCatObj).subscribe(
			{
				next: (res: any) => {
					if (res.success == 1) {
						this.toastrService.success(res.message);
						this.addCategoryForm.reset();
						this.ckeditor.instance.setData('');
						($('#addCategoryFormModal') as any).modal('hide');

						let engQuestionsArray = this.engQuestionAnsForm.get('engQuestions') as FormArray;
						engQuestionsArray.controls = [];
						engQuestionsArray.setValue([]);

						let spnQuestionsArray = this.spnQuestionAnsForm.get('spnQuestions') as FormArray;
						spnQuestionsArray.controls = [];
						spnQuestionsArray.setValue([]);
						this.getCategoryLists();


					}
					else {
						this.toastrService.error(res.message);
						($('#addCategoryFormModal') as any).modal('show');
					}
				},
				error: (error: any) => { }
			});
	}

	public isEngFormValid(): boolean {
		const engQuestionsArray = this.engQuestionAnsForm.get('engQuestions') as FormArray;
		if (!engQuestionsArray || engQuestionsArray.length === 0) {
			return false;
		}
		for (let i = 0; i < engQuestionsArray.length; i++) {
			const question = engQuestionsArray.at(i) as FormGroup;
			const questionType = question.get('eng_type').value;

			const eng_que = question.get('eng_que');
			if ((eng_que.invalid && (eng_que.dirty || eng_que.touched)) || (eng_que.errors && eng_que.errors.required)) {
				return false;
			}


			const optionsArray = question.get('engOptions') as FormArray;
			if (questionType === 'text') {
				continue;
			}
			else if (questionType === 'radio') {
				if (!optionsArray || optionsArray.length === 0 || optionsArray.value.some(option => option.eng_option.trim() === '')) {
					return false;
				}
			}
		}
		return true;
	}

	public isSpnFormValid(): boolean {
		const spnQuestionsArray = this.spnQuestionAnsForm.get('spnQuestions') as FormArray;
		if (!spnQuestionsArray || spnQuestionsArray.length === 0) {
			return false;
		}

		for (let i = 0; i < spnQuestionsArray.length; i++) {
			const question = spnQuestionsArray.at(i) as FormGroup;
			const questionType = question.get('spn_type').value;

			const spn_que = question.get('spn_que');
			if ((spn_que.invalid && (spn_que.dirty || spn_que.touched)) || (spn_que.errors && spn_que.errors.required)) {
				return false;
			}

			const optionsArray = question.get('spnOptions') as FormArray;
			if (questionType === 'text') {
				continue
			}
			else if (questionType === 'radio') {
				if (!optionsArray || optionsArray.length === 0 || optionsArray.value.some(option => option.spn_option.trim() === '')) {
					return false;
				}
			}
		}
		return true;
	}

	// <<<<<<================== formArray for English Ques Ans ========================>>>>>>>>>>>>>
	public initEngQuestion(questionId: string) {
		return this.formBuilder.group({
			que_id: [questionId],
			eng_que: ['', [Validators.required]],
			eng_type: ['radio', Validators.required],
			engOptions: this.formBuilder.array([])
		});
	}

	public engQuestions(): FormArray {
		return this.engQuestionAnsForm.get('engQuestions') as FormArray;
	}

	control: any
	public addEngQues() {
		this.control = <FormArray>this.engQuestionAnsForm.controls['engQuestions'];
		let maxEngQuestionId = 0;
		this.control.value.forEach((question: any) => {
			let questionEngId = parseInt(question.id);
			if (questionEngId > maxEngQuestionId) {
				maxEngQuestionId = questionEngId;
			}
		});
		let newQuestionEngId = (maxEngQuestionId + 1).toString();
		this.control.push(this.initEngQuestion(newQuestionEngId));
		this.showEngAddBtn.push(true);
	}


	public editEngQues(qusIndex: any) {
		console.log("qusIndex", qusIndex);

	}

	public removeEngQues(index: number) {
		let control = <FormArray>this.engQuestionAnsForm.controls['engQuestions'];
		control.removeAt(index);
	}

	engIndex: any = "";
	public onEngChangeType(selectedType: string, index: number) {
		console.log("selectedType=>>>>>>", selectedType);
		console.log("index=>>>>>>", index);
		this.engIndex = index;

		let engQuestionsArray = this.engQuestionAnsForm.get('engQuestions') as FormArray;
		let control = engQuestionsArray.at(index) as FormGroup;

		if (control) {
			if (selectedType === 'radio') {
				control.setControl('engOptions', this.formBuilder.array([]));
				// this.addEngOption(index);
			} else {
				control.removeControl('engOptions');
			}
		} else {
			console.error(`Control at index ${index} is undefined.`);
		}
		// const engQuestionsArray = this.engQuestions();
		// const engOptions = (engQuestionsArray.at(index) as FormGroup).get('engOptions') as FormArray;
		// const engOptions = (this.engQuestions().at(index) as FormGroup).get('engOptions') as FormArray;




		// if (selectedType === 'text') {
		// 	engOptions.clear(); 
		// } else {
		// 	this.addEngOption(index);
		// }
	}

	engControl: any;
	engOptionId: any;
	// showEngAddBtn: boolean = true;
	showEngAddBtn: boolean[] = [true];
	public addEngOption(questionIndex: number) {
		this.engControl = (this.engQuestionAnsForm.get('engQuestions') as FormArray).at(questionIndex).get('engOptions') as FormArray;
		if (this.engControl.length < 5) {
			this.engOptionId = this.engControl.length + 1;
			this.engControl.push(this.formBuilder.group({
				ans_id: [this.engOptionId],
				eng_option: ['', [Validators.required]]
			}));
			// this.showEngAddBtn[questionIndex] = true;
		}
		this.showEngAddBtn[questionIndex] = this.engControl.length < 5;
		if (this.engControl.length === 5) {
			// Disable the button after 5 options have been added
			this.disableAddOptionButton(questionIndex);
		}
	}

	public disableAddOptionButton(questionIndex: number) {
		this.showEngAddBtn[questionIndex] = false;
	}

	public removeEngOption(questionIndex: number, optionIndex: number) {
		let control = (this.engQuestionAnsForm.get('engQuestions') as FormArray).at(questionIndex).get('engOptions') as FormArray;
		control.removeAt(optionIndex);
		this.showEngAddBtn[questionIndex] = true;
		this.showEditEngAddBtn[questionIndex] = true;
	}
	// <<<<<<================== formArray for English Ques Ans ========================>>>>>>>>>>>>>

	// <<<<<<================== formArray for Spanish Ques Ans ========================>>>>>>>>>>>>>
	initSpnQuestion(spnQuestionId: string) {
		return this.formBuilder.group({
			que_id: [spnQuestionId],
			spn_que: ['', Validators.required],
			spn_type: ['radio', Validators.required],
			spnOptions: this.formBuilder.array([])
		});
	}

	spnQuestions(): FormArray {
		return this.spnQuestionAnsForm.get('spnQuestions') as FormArray;
	}

	addSpnQues() {
		let control = <FormArray>this.spnQuestionAnsForm.controls['spnQuestions'];
		// control.push(this.initSpnQuestion());
		let maxSpnQuestionId = 0;
		control.value.forEach((question: any) => {
			let questionSpnId = parseInt(question.id);
			if (questionSpnId > maxSpnQuestionId) {
				maxSpnQuestionId = questionSpnId;
			}
		});

		let newQuestionSpnId = (maxSpnQuestionId + 1).toString();
		control.push(this.initSpnQuestion(newQuestionSpnId));
		this.showSpnAddBtn.push(true);
	}

	editSpnQues(qusIndex: any) {
		console.log("qusIndex", qusIndex);
	}

	removeSpnQues(index: number) {
		let control = <FormArray>this.spnQuestionAnsForm.controls['spnQuestions'];
		control.removeAt(index);
	}

	spn_ans_text: any = ""
	onSpnChangeType(selectedType: string, index: number) {
		console.log("selectedType=>>>>>>", selectedType);
		console.log("index=>>>>>>", index);

		let spnQuestionsArray = this.spnQuestionAnsForm.get('spnQuestions') as FormArray;
		let control = spnQuestionsArray.at(index) as FormGroup;

		if (control) {
			if (selectedType === 'radio') {
				// control.setControl('options', this.formBuilder.array([this.createOption()]));
				control.setControl('spnOptions', this.formBuilder.array([]));
			} else {
				control.removeControl('spnOptions');
			}
		} else {
			console.error(`Control at index ${index} is undefined.`);
		}
	}

	showSpnAddBtn: boolean[] = [true];
	addSpnOption(questionIndex: number) {
		let control = (this.spnQuestionAnsForm.get('spnQuestions') as FormArray).at(questionIndex).get('spnOptions') as FormArray;
		if (control.length < 5) {
			let optionId = control.length + 1;
			control.push(this.formBuilder.group({
				ans_id: [optionId],
				spn_option: ['', [Validators.required]]
			}));
			// this.showSpnAddBtn[questionIndex] = true
		}
		this.showSpnAddBtn[questionIndex] = control.length < 5;
		if (control.length === 5) {
			this.disableSpnAddOptionButton(questionIndex);
		}
	}
	public disableSpnAddOptionButton(questionIndex: number) {
		this.showSpnAddBtn[questionIndex] = false;
	}

	removeSpnOption(questionIndex: number, optionIndex: number) {
		let control = (this.spnQuestionAnsForm.get('spnQuestions') as FormArray).at(questionIndex).get('spnOptions') as FormArray;
		control.removeAt(optionIndex);
		this.showSpnAddBtn[questionIndex] = true;
		this.showEditSpnAddBtn[questionIndex] = true;
	}
	// <<<<<<================== formArray for Spanish Ques Ans ========================>>>>>>>>>>>>>

	// <<<<<<================== edit formArray for Ques Ans ========================>>>>>>>>>>>>>
	public editAddEngQues() {
		this.control = <FormArray>this.engQuestionAnsForm.controls['engQuestions'];
		this.control.push(this.editInitEngQuestion());
		this.showEditEngAddBtn.push(true);
	}

	public editInitEngQuestion() {
		return this.formBuilder.group({
			ques_id: [0],
			eng_que: ['', [Validators.required]],
			eng_type: ['radio', Validators.required],
			engOptions: this.formBuilder.array([])
		});
	}

	editAddSpnQues() {
		let control = <FormArray>this.spnQuestionAnsForm.controls['spnQuestions'];
		control.push(this.editInitSpnQuestion());
		this.showEditSpnAddBtn.push(true);
	}

	editInitSpnQuestion() {
		return this.formBuilder.group({
			ques_id: [0],
			spn_que: ['', [Validators.required]],
			spn_type: ['radio', Validators.required],
			spnOptions: this.formBuilder.array([])
		});
	}

	showEditEngAddBtn: boolean[] = [true];
	addEditEngOption(questionIndex: number) {
		let engControl = (this.engQuestionAnsForm.get('engQuestions') as FormArray).at(questionIndex).get('engOptions') as FormArray;
		if (engControl.length < 5) {
			this.engOptionId = engControl.length + 1;
			engControl.push(this.formBuilder.group({
				ans_id: [0],
				eng_option: ['', [Validators.required]]
			}));
			this.showEditEngAddBtn[questionIndex] = true;
		}
		this.showEditEngAddBtn[questionIndex] = engControl.length < 5;
		if (engControl.length === 5) {
			this.disableEditEngAddOptionButton(questionIndex);
		}
	}
	public disableEditEngAddOptionButton(questionIndex: number) {
		this.showEditEngAddBtn[questionIndex] = false;
	}

	showEditSpnAddBtn: boolean[] = [true];
	addEditSpnOption(questionIndex: number) {
		let spnControl = (this.spnQuestionAnsForm.get('spnQuestions') as FormArray).at(questionIndex).get('spnOptions') as FormArray;
		if (spnControl.length < 5) {
			this.engOptionId = spnControl.length + 1;
			spnControl.push(this.formBuilder.group({
				ans_id: [0],
				spn_option: ['', [Validators.required]]
			}));
			this.showEditSpnAddBtn[questionIndex] = true;
		}
		this.showEditSpnAddBtn[questionIndex] = spnControl.length < 5;
		if (spnControl.length === 5) {
			this.disableEditSpnAddOptionButton(questionIndex);
		}
	}
	public disableEditSpnAddOptionButton(questionIndex: number) {
		this.showEditSpnAddBtn[questionIndex] = false;
	}

	onEditChange(event: any) {
		console.log("in edit->>>onChanhe->event", event.target.checked);

		// let engQuestionsArray = this.engQuestionAnsForm.get('engQuestions') as FormArray;
		// while (engQuestionsArray.length !== 0) {
		// 	engQuestionsArray.removeAt(0);
		// }

		// let spnQuestionsArray = this.spnQuestionAnsForm.get('spnQuestions') as FormArray;
		// while (spnQuestionsArray.length !== 0) {
		// 	spnQuestionsArray.removeAt(0);
		// }

		if (event.target.checked == true) {
			console.log("in True Condtion");
			this.showQuesAns = true;
		}
		else {
			console.log("in false Condtion");
			this.showQuesAns = false;
			// let questionsEngArray = this.engQuestionAnsForm.get('engQuestions') as FormArray;
			// questionsEngArray.controls = [];
			// questionsEngArray.setValue([]);
			// let questionsSpnArray = this.spnQuestionAnsForm.get('spnQuestions') as FormArray;
			// questionsSpnArray.controls = [];
			// questionsSpnArray.setValue([]);
		}

	}

	public getCategoryListsById(catID: any) {
		let catObj =
		{
			cat_id: catID
		}
		this.CategoryService.getCategoryDetailsById(catObj).subscribe(
			{
				next: (res: any) => {
					if (res.success == 1) {
						// this.categoryData = res.data;
						console.log("this.categoryData by id=>", res.data);

						this.loader = false;
					}
					else {

					}
				},
				error: (error: any) => { }
			});
	}

	selectedCategory: any;
	categoryEditModel(category: any) {
		let questionsEngArray = this.engQuestionAnsForm.get('engQuestions') as FormArray;
		questionsEngArray.controls = [];
		questionsEngArray.setValue([]);
		let questionsSpnArray = this.spnQuestionAnsForm.get('spnQuestions') as FormArray;
		questionsSpnArray.controls = [];
		questionsSpnArray.setValue([]);
		this.stepper2.reset();
		this.selectedCategory = category;
		($('#editCategoryFormModal') as any).modal('show');
		if (category.is_que == 1) {
			console.log("in True Condtion");
			this.selectQuesAns = true;
			this.showQuesAns = true;
		}
		else {
			console.log("in false Condtion");
			this.selectQuesAns = false;
			this.showQuesAns = false;
		}
		this.editCategoryForm.patchValue({
			cat_name: category.cat_name,
			cat_description: category.cat_description,
			cat_note: category.cat_note,
		});

		let engQuestionsArray = this.engQuestionAnsForm.get('engQuestions') as FormArray;
		console.log("Patch values from quesAnsEng array-> engQuestionsArray=>>>", engQuestionsArray);
		for (let question of category.quesAnsEng) {
			if (!question.ques_id) {
				question.ques_id = 0;
			}
			let questionGroup = this.formBuilder.group({
				ques_id: [question.ques_id, Validators.required],
				eng_que: [question.eng_que, [Validators.required]],
				eng_type: [question.eng_type, Validators.required],
				engOptions: this.buildEngOptionsArray(question.engOptions)
			});
			engQuestionsArray.push(questionGroup);
		}
		let spnQuestionsArray = this.spnQuestionAnsForm.get('spnQuestions') as FormArray;
		console.log("Patch values from quesAnsEng array -> spnQuestionsArray=>>>>>", spnQuestionsArray);
		for (let question of category.quesAnsSpn) {
			if (!question.ques_id) {
				question.ques_id = 0;
			}
			let questionGroup = this.formBuilder.group({
				ques_id: [question.ques_id, Validators.required],
				spn_que: [question.spn_que, Validators.required],
				spn_type: [question.spn_type, Validators.required],
				spnOptions: this.buildOptionsArray(question.spnOptions)
			});
			spnQuestionsArray.push(questionGroup);
		}
		//<============== test code 001 ===================>
	}

	// test code 001
	buildEngOptionsArray(options): FormArray {
		if (!options) {
			return this.formBuilder.array([]);
		}
		const optionsArray = options.map(option => {
			if (!option.ans_id) {
				option.ans_id = 0;
			}

			return this.formBuilder.group({
				ans_id: [option.ans_id],
				eng_option: [option.eng_option, Validators.required]
			});
		});
		return this.formBuilder.array(optionsArray);
	}

	buildOptionsArray(options): FormArray {
		if (!options) {
			return this.formBuilder.array([]);
		}
		const optionsArray = options.map(option => {
			if (!option.ans_id) {
				option.ans_id = 0;
			}

			return this.formBuilder.group({
				ans_id: [option.ans_id],
				spn_option: [option.spn_option, Validators.required]
			});
		});
		return this.formBuilder.array(optionsArray);
	}
	// <===============test code 001=================>>>>>>>>>

	public updateCategory(editCategoryFormValue: any) {
		console.log("on Update =>>editCategoryFormValue", editCategoryFormValue);
		console.log("on Update =>>editCategoryFormValue.id", editCategoryFormValue.id);
		console.log("selectQuesAns=>>>", this.selectQuesAns);
		console.log("formArray=>>>>>>", this.engQuestionAnsForm.value.engQuestions);
		console.log("formArray=>>>>>>", this.spnQuestionAnsForm.value.spnQuestions);

		let editCatObj = {
			cat_id: this.selectedCategory.id,
			cat_name: editCategoryFormValue.cat_name,
			cat_description: editCategoryFormValue.cat_description,
			cat_note: editCategoryFormValue.cat_note,
			showQusAns: this.selectQuesAns,
			quesAnsEng: this.engQuestionAnsForm.value.engQuestions,
			quesAnsSpn: this.spnQuestionAnsForm.value.spnQuestions,

		};

		console.log("edit cat obj=>", editCatObj);
		this.CategoryService.editCategoryDetails(editCatObj).subscribe(
			{
				next: (res: any) => {
					if (res.success == 1) {
						this.toastrService.success(res.message);
						this.getCategoryLists();
						($('#editCategoryFormModal') as any).modal('hide');
					}
					else {
						this.toastrService.error(res.message);
						($('#editCategoryFormModal') as any).modal('show');
					}
				},
				error: (error: any) => { }
			});


	}


	categorydeleteModal(id) {
		this.selectedCompanyId = id;
		console.log("this.selectedCompanyId", this.selectedCompanyId);
		($('#deleteCategory') as any).modal('show');
	}

	deleteCategoryConfirm() {
		let deleteCategoryObj = {
			cat_id: this.selectedCompanyId
		}
		console.log("deleteCategoryObj", deleteCategoryObj);

		this.CategoryService.deleteCategoryDetails(deleteCategoryObj).subscribe(res => {
			if (res.success == 1) {
				($('#deleteCategory') as any).modal("hide");
				this.toastrService.success(res.message);
				this.getCategoryLists();
			} else {
				this.toastrService.error(res.message);
			}
		})
	}
	// <<<<<<================== edit formArray for Ques Ans ========================>>>>>>>>>>>>>



}
