import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserdirectoryService } from './userdirectory.service';
import { FormGroup, FormControl, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CustomValidators } from 'ng2-validation';
import { ConfirmedValidator } from 'src/app/confirmed.validator';


@Component({
  selector: 'app-userdirectory',
  templateUrl: './userdirectory.component.html',
  styleUrls: ['./userdirectory.component.scss']
})
export class UserdirectoryComponent implements OnInit {

  public userDetail: any;
  addUser: FormGroup;
  editUser: FormGroup;
  resetPasswordForm: FormGroup;
  public projects: any;
  selectedProject: any = [];
  userRoles = [
    { role_id: 0, value: 'Admin' },
    { role_id: 1, value: 'Project Manager' },
  ];
  selectedItems = [];
  disabled = false;
  project_assigned: any = [];
  deletuserId: any;
  public userData;
  assign_user: any = [];
  public selecteduser: any;
  selectedrole: any;
  public user_roles: any;
  public loader = true;

  constructor(
    private formBuilder: FormBuilder,
    private route: Router,
    private userdirService: UserdirectoryService,
    private toastrService: ToastrService,

  ) { }

  ngOnInit(): void {
    this.user_roles = localStorage.getItem('currentUserRoleId');
    // console.log("user_role",this.user_roles)
    this.formValidation();
    this.getUserList();
    this.getProjectList();
  }

  formValidation() {
    this.addUser = this.formBuilder.group({
      name: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [
        Validators.compose([Validators.required, CustomValidators.email])
      ]),
      designation: new FormControl('', [
        Validators.required
      ]),
      role: new FormControl('', [
        Validators.required
      ]),
      assign_project: new FormControl('', [
      ])
    });
    this.editUser = this.formBuilder.group({
      name: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [
        Validators.compose([Validators.required, CustomValidators.email])
      ]),
      designation: new FormControl('', [
        Validators.required
      ]),
      role: new FormControl('', [
        Validators.required
      ]),
      assign_project: new FormControl('', [
      ])
    });
    this.resetPasswordForm = this.formBuilder.group({
      'newpass': ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      'confirmPass': ['', Validators.compose([Validators.required, Validators.minLength(6)])]
    },
      {
        validator: ConfirmedValidator('newpass', 'confirmPass')
      });
  }

  getUserList() {
    let Obj = {
      user_id: localStorage.getItem('currentUserId'),
    }
    this.userdirService.getUserList(Obj).subscribe(res => {
      if (res.success == 1) {
        this.userDetail = res.data;
        this.loader = false;
        console.log(this.userDetail);
      }
    })
  }

  getProjectList() {
    let Obj = {
      'user_id': localStorage.getItem('currentUserId')
    }
    this.userdirService.getProjectList(Obj).subscribe(res => {
      if (res.success == 1) {
        this.projects = res.data;
        console.log(this.projects);
      }
    })
  }

  openaddUserModal() {
    this.addUser.reset();
    ($('#adduserModal') as any).modal('show');
  }


  onAddChangeRole(selectedrole) {
    console.log("selectedrole=>", selectedrole);
    // this.selectedrole = selectedrole
    if (selectedrole === 0) {

      this.addUser.controls['assign_project'].setValue("");
      this.addUser.controls['assign_project'].markAsUntouched();
      this.addUser.controls['assign_project'].clearValidators();
      this.addUser.controls['assign_project'].updateValueAndValidity();
    }
    else {
      this.addUser.controls['assign_project'].markAsUntouched();
      this.addUser.controls['assign_project'].setValidators([Validators.required])
      this.addUser.controls['assign_project'].updateValueAndValidity();
    }
  }

  saveUser(userdata) {

    let assignedProjectId
    if (userdata.role == 1) {
      assignedProjectId = userdata.assign_project;
    }
    else {
      assignedProjectId = [];
    }


    let Obj = {
      name: userdata.name,
      email: userdata.email,
      designation: userdata.designation,
      role: userdata.role,
      // assign_project: userdata.assign_project
      assign_project: assignedProjectId
    };


    console.log("on Add Users userData=>", Obj);


    this.userdirService.addUser(Obj).subscribe(
      {
        next: (res: any) => {
          if (res.success == 1) {
            this.toastrService.success(res.message);
            ($('#adduserModal') as any).modal("hide");
            this.getUserList();
          }
          else {
            this.toastrService.error(res.message);
            // ($('#adduserModal') as any).modal("hide");
            return false;
          }
        },
        error: (error: any) => {
          ($('#adduserModal') as any).modal('hide');
          return false;
        }
      });
  }


  onEditChangeRole(selectedrole: any) {
    console.log("selectedrole=>", selectedrole);
    // this.selectedrole = selectedrole
    if (selectedrole === 0) {

      this.editUser.controls['assign_project'].setValue("");
      this.editUser.controls['assign_project'].markAsUntouched();
      this.editUser.controls['assign_project'].clearValidators();
      this.editUser.controls['assign_project'].updateValueAndValidity();
    }
    else {
      this.editUser.controls['assign_project'].markAsUntouched();
      this.editUser.controls['assign_project'].setValidators([Validators.required])
      this.editUser.controls['assign_project'].updateValueAndValidity();
    }
  }


  userEditModel(user) {
    this.selecteduser = user;
    ($('#usereditModal') as any).modal('show');

    if (user.role === 1) {
      this.editUser.controls['assign_project'].setValue(user.assign_user);
      this.editUser.controls['assign_project'].markAsUntouched();
      this.editUser.controls['assign_project'].setValidators([Validators.required])
      this.editUser.controls['assign_project'].updateValueAndValidity();
    }
    else {
      this.editUser.controls['assign_project'].setValue("");
      this.editUser.controls['assign_project'].markAsUntouched();
      this.editUser.controls['assign_project'].clearValidators();
      this.editUser.controls['assign_project'].updateValueAndValidity();
    }

    let selectedProjects = [];

    // Loop through each project in user's assigned projects
    user.assign_user.forEach(projectId => {
      // Find the corresponding project in the projects array and push its ID
      let project = this.projects.find(project => project.id === projectId);
      if (project) {
        selectedProjects.push(project.id);
      }
    });


    // this.selectedrole = user.role;
    console.log("selectedRole->", this.selectedrole);
    console.log("user.role->", user.role);
    this.editUser.patchValue(
      {
        'name': user.name,
        'email': user.email,
        'designation': user.designation,
        'role': user.role,
        // 'assign_project': user.assign_user
        'assign_project': selectedProjects
      }
    );
    console.log("this.editUser->>>>>>", this.editUser.value.assign_project);
  }

  updateUser(euserdata) {
    console.log(euserdata);
    let pro_id = [];
    this.selecteduser.assign_user.forEach(element => {
      pro_id.push(element.id)
    });

    let assignedProjectId
    if (euserdata.role == 1) {
      assignedProjectId = euserdata.assign_project;
    }
    else {
      assignedProjectId = [];
    }


    let Obj = {
      user_id: this.selecteduser.id,
      name: euserdata.name,
      email: euserdata.email,
      designation: euserdata.designation,
      role: euserdata.role,
      assign_project: assignedProjectId
    }
    console.log(Obj);
    this.userdirService.editUser(Obj).subscribe((res: any) => {
      if (res.success == 1) {
        this.toastrService.success(res.message);
        ($('#usereditModal') as any).modal("hide");
        this.getUserList();
      }
      else {
        this.toastrService.error(res.message);
        ($('#usereditModal') as any).modal("hide");
        return false;
      }
    }, error => {
      ($('#usereditModal') as any).modal('hide');
      return false;
    }
    )
  }


  userdeleteModal(id) {
    this.deletuserId = id;
    ($('#deleteUser') as any).modal('show');
  }

  deleteUserConfirm() {
    let Obj = {
      user_id: this.deletuserId
    }
    console.log(this.deletuserId);
    this.userdirService.deleteUser(Obj).subscribe((res: any) => {
      {
        if (res.success == 1) {
          ($('#deleteUser') as any).modal("hide");
          this.toastrService.success(res.message);
          this.getUserList();
        } else {
          this.toastrService.error(res.message);
        }
      }
    })
  }

  openAssignModel(user) {
    console.log(this.projects);

    this.projects.forEach(element => { element.checked = false; });
    this.projects.forEach(element => {

      user.assign_user.forEach(e => {
        console.log(element.id, e);
        if (element.id == e) {
          console.log(element.id, e);
          element.checked = true;
        }
      });
    });

    this.selectedProject = [];
    var selectallbtn = Array();
    var t = this.projects.filter(opt => opt.checked)
    selectallbtn = t;
    selectallbtn.forEach(element => {
      this.selectedProject.push(element.id);
    });
    this.userData = user;
    ($('#projectAssignModal') as any).modal('show');
  }


  updateAssignProjects() {
    let Obj = {
      project_id: this.selectedProject,
      user_id: this.userData.id,
    }
    console.log(Obj);
    this.userdirService.projectAssign(Obj).subscribe((res: any) => {
      if (res.success == 1) {
        this.toastrService.success(res.message);
        ($('#projectAssignModal') as any).modal('hide');
        this.getUserList();
      }
      else {
        this.toastrService.error(res.message);
        ($('#projectAssignModal') as any).modal('hide');
      }
    },
      (error) => {
        this.toastrService.error(error.message);
      })
  }


  checkAll(ev) {
    this.selectedProject = [];
    this.projects.forEach(x => {
      x.checked = ev.target.checked;
    });
    var selectallbtn = Array();
    var t = this.projects.filter(opt => opt.checked)
    selectallbtn = t;
    selectallbtn.forEach(element => {
      this.selectedProject.push(element.project_id);
    });
    console.log(this.selectedProject);

  }

  isAllCheckBoxChecked() {
    if (this.projects) {
      return this.projects.every(p => p.checked);
    }
  }


  checkOne(project, e) {
    this.selectedProject = [];
    project.checked = e.target.checked;
    var selectallbtn = Array();
    var t = this.projects.filter(opt => opt.checked)
    selectallbtn = t;
    selectallbtn.forEach(element => {
      this.selectedProject.push(element.id);
    });
    console.log("checkval", this.selectedProject)

  }

  public currentUserID: any;

  openResetPasswordModel(user) {
    this.resetPasswordForm.reset();
    this.currentUserID = user.id;
    console.log(this.currentUserID);
    ($('#passwordReset') as any).modal('show');
  }

  public onChangePassword(values: Object): void {
    console.log(this.resetPasswordForm.value);
    if (this.resetPasswordForm.valid) {
      this.changePasswordAPI(this.resetPasswordForm.value);
    }
  }

  changePasswordAPI(changePasswordata) {
    console.log(changePasswordata);
    let Obj = {
      'user_id': this.currentUserID,
      'password': changePasswordata.confirmPass,
    }
    console.log(Obj);
    this.userdirService.changePasswordAPI(Obj).subscribe((res: any) => {
      if (res.success == 1) {
        this.toastrService.success(res.message);
        ($('#passwordReset') as any).modal('hide');
        this.getUserList();
      }
      else {
        this.toastrService.error(res.message);
        ($('#passwordReset') as any).modal('hide');
      }
    },
      (error) => {
        this.toastrService.error(error.message);
      })
  }

  // clearForm(){
  //   this.resetPasswordForm.reset();
  // }
  fieldTextType: boolean;
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  newFieldText: boolean;
  toggleNewPasswordType() {
    this.newFieldText = !this.newFieldText;
  }

}
