import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(public http: HttpClient) { }
  
  videoList() {
    return this.http.get<any>(environment.apiUrl + '/list_global_video')
  }

  updateVideo(formData) {
    return this.http.post<any>(environment.apiUrl + '/edit_global_video',formData)
  }


}
