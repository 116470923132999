import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  exportExcelDate(Obj) {
    return this.http.post<any>(environment.apiUrl + '/export_excel_date', Obj
    )
  }

  
}
