import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from "@angular/forms";
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { ReportsService } from '../reports/reports.service';

@Component({
  selector: 'app-category-reports',
  templateUrl: './category-reports.component.html',
  styleUrls: ['./category-reports.component.scss']
})
export class CategoryReportsComponent implements OnInit {

  // public userDetail = JSON.parse(localStorage.getItem('userDetails'));
  public Question: any[] = [];
  public categoryReportDetails: any = [];
  public categoryId: any;
  public originalUserDetail: any;
  public yesCount = 0;
  public question_ans: any = [];
  public project_name = localStorage.getItem('currentUser_projectName');

  constructor(private reportService: ReportsService,
    private fromBuilder: FormBuilder,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.Question = JSON.parse(localStorage.getItem('categoryQuestionList'));
    this.categoryId = localStorage.getItem('categoryId');
    // this.categoryReportDetails =JSON.parse(localStorage.getItem('categoryDetails'));
    let storedData = localStorage.getItem('categoryDetails');
    console.log("storedData", storedData);
    this.categoryReportDetails = JSON.parse(storedData);
    console.log(this.categoryId);
    console.log(this.Question);
    console.log(this.categoryReportDetails);
    // this.categoryReportDetails.questions.forEach(element => {
    //   element.forEach(ele => {
    //     element.created_at = ele.created_at;
    //     if (ele.answer != "No") {
    //       this.yesCount++;
    //     }
    //   });
    //   if (this.yesCount >= 1) {
    //     element.is_symptomatic = "yes";
    //   }
    //   else {
    //     element.is_symptomatic = "No";
    //   }
    // })
    this.originalUserDetail = JSON.parse(JSON.stringify(this.categoryReportDetails));

  }

  ngAfterViewChecked() {
    //your code to update the model
    this.cdr.detectChanges();
  }

  public category_start_date: any;
  public category_end_date: any;
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: 'dd-mm-yyyy',
    // disableUntil: {year: this.startDate.getFullYear(), month: this.startDate.getMonth() + 1, day: this.startDate.getDate() - 1}
  };


  public myCategoryDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: 'dd-mm-yyyy',
  };
  public myCategoryEndDateOptions: IAngularMyDpOptions = {
    dateFormat: 'dd-mm-yyyy',
  };

  onCategoryStartDateChange() {
    if (this.category_start_date != null) {
      const startDate = formatDate(this.category_start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
      this.myCategoryEndDateOptions = {
        ...this.myCategoryEndDateOptions,
        disableUntil: {
          year: this.category_start_date.singleDate.date.year,
          month: this.category_start_date.singleDate.date.month,
          day: this.category_start_date.singleDate.date.day - 1
        }
      };
      // If end date is before start date, reset end date
      if (this.category_end_date && this.category_end_date.singleDate.jsDate < this.category_start_date.singleDate.jsDate) {
        this.category_end_date = null;
      }
    }
    // Apply filter when both start and end dates are selected
    if (this.category_start_date != null && this.category_end_date != null) {
      let startDate = formatDate(this.category_start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
      let toDate = formatDate(this.category_end_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
      this.categoryReportDetails.questions = this.originalUserDetail.questions.filter(item => {
        let date = formatDate(item[0].created_at, 'yyyy-MM-dd', 'en-US');
        return date > startDate && date < toDate;
      });

    }
  }

  onCategoryFilter() {
    if (this.category_start_date != null && this.category_end_date != null) {

      let startDate = formatDate(this.category_start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
      let toDate = formatDate(this.category_end_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
      this.categoryReportDetails.questions = this.originalUserDetail.questions.filter(item => {
        let date = formatDate(item[0].created_at, 'yyyy-MM-dd', 'en-US');
        return date > startDate && date < toDate;
      });

    }
  }


  onCategorySubmit() {
    console.log("category_start_date=>", this.category_start_date);
    console.log("category_end_date=>", this.category_end_date);

    if (this.category_start_date && this.category_end_date) {

       let project_id = localStorage.getItem("currentUser_projectId");
       let fromDate = formatDate(this.category_start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
       let toDate = formatDate(this.category_end_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
       console.log(window.open(environment.apiUrl + '/export_dynamic_excel?project_id=' + project_id + '&user_id=' + this.categoryReportDetails.user_id + '&from=' + fromDate + '&to=' + toDate + '&cat_id=' + this.categoryId));
       window.open(environment.apiUrl + '/export_dynamic_excel?project_id=' + project_id + '&user_id=' + this.categoryReportDetails.user_id + '&from=' + fromDate + '&to=' + toDate + '&cat_id=' + this.categoryId, "blank");
       this.category_start_date = "";
       this.category_end_date = "";
    }
    else {
       let project_id = localStorage.getItem("currentUser_projectId");
       console.log(window.open(environment.apiUrl + '/export_dynamic_excel?project_id=' + project_id + '&user_id=' + this.categoryReportDetails.user_id + '&from=' + null + '&to=' + null + '&cat_id=' + this.categoryId, "_self"));
       window.open(environment.apiUrl + '/export_dynamic_excel?project_id=' + project_id + '&user_id=' + this.categoryReportDetails.user_id + '&from=' + null + '&to=' + null + '&cat_id=' + this.categoryId, "_self");
       this.category_start_date = "";
       this.category_end_date = "";
    }
 }


  

  // onSubmit() {
  //   if (this.start_date && this.end_date) {
  //     let project_id = localStorage.getItem("currentUser_projectId");
  //     let fromDate = formatDate(this.start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
  //     let toDate = formatDate(this.end_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
  //     window.open(environment.apiUrl + '/export_dynamic_excel?project_id=' + project_id + '&user_id=' + this.categoryReportDetails.user_id + '&from=' + fromDate + '&to=' + toDate + '&cat_id=' + this.categoryId, "_self");
  //   }
  //   else {
  //     let project_id = localStorage.getItem("currentUser_projectId");
  //     console.log(environment.apiUrl + '/export_dynamic_excel?project_id=' + project_id + '&user_id=' + this.categoryReportDetails.user_id + '&from=' + null + '&to=' + null + '&cat_id=' + this.categoryId,);
  //     window.open(environment.apiUrl + '/export_dynamic_excel?project_id=' + project_id + '&user_id=' + this.categoryReportDetails.user_id + '&from=' + null + '&to=' + null + '&cat_id=' + this.categoryId, "_self");
  //   }
  // }


  openQuestionModel(question) {
    ($('#categoryQueModal') as any).modal('show');
    this.question_ans = question;
    console.log(this.question_ans);

    this.question_ans = question.map(q => ({
      answer: q.answer,
      answers_id: q.answers_id
    }));
  }

}
