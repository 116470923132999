import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as $ from "jquery";

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  constructor(public router:Router) { 
    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
      }
    })
  }

  ngOnInit(): void {
  }

}
