import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormDetailsService } from './form-details.service';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { IAngularMyDpOptions, IMyOptions, IMyDateModel } from 'angular-mydatepicker';
import * as $ from "jquery";
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';



@Component({
   selector: 'app-form-details',
   templateUrl: './form-details.component.html',
   styleUrls: ['./form-details.component.scss']
})
export class FormDetailsComponent implements OnInit {

   public userdetails: any;
   public videodetails: any;
   public categoryDetails: any;
   public selectedproject: any;
   public loader = true;
   public exportForm: FormGroup;
   public myForm: FormGroup;
   public videoexportForm: FormGroup;
   public user_id;
   public originalUserDetails: any;
   public originalVideoDetails: any;
   public originalCategoryDetails: any;
   public textToCopy: any;
   public project_name = localStorage.getItem('currentUser_projectName');
   public video_start_date: any;
   public video_end_date: any;
   public category_start_date: any;
   public category_end_date: any;
   public start_date: any;
   public end_date: any;
   public dummyData: any[] = [];

   constructor(private formdetails: FormDetailsService,
      private fromBuilder: FormBuilder,
      private router: Router,
      private toastrService: ToastrService,
      private cdr: ChangeDetectorRef) { }

   ngOnInit(): void {
      this.formValidatation();
      this.getSubmittedFormDetails();
      this.getVideoSubmittedFormDetails();
      this.getCategoryLists();
   }
   ngAfterViewChecked() {
      this.cdr.detectChanges();
   }

   formValidatation() {

   }

   getSubmittedFormDetails() {
      let Obj = {
         'project_id': localStorage.getItem('currentUser_projectId')
      }
      this.formdetails.getSubmittedForm(Obj).subscribe(res => {
         if (res.success == 1) {
            this.userdetails = res.data;
            this.originalUserDetails = JSON.parse(JSON.stringify(this.userdetails))
            this.loader = false;
            localStorage.setItem('questionList', JSON.stringify(res.question));
            console.log(this.userdetails);

            if (!this.userdetails || this.userdetails.length === 0) {
               $('.loadingContent').html('No data found.');
            }
         }
      })
   }

   getVideoSubmittedFormDetails() {
      let Obj = {
         'project_id': localStorage.getItem('currentUser_projectId')
      }
      this.formdetails.getVideoSubmittedForm(Obj).subscribe(res => {
         if (res.success == 1) {
            this.videodetails = res.data;
            this.originalVideoDetails = JSON.parse(JSON.stringify(this.videodetails))
            this.loader = false;
            localStorage.setItem('videoQuestionList', JSON.stringify(res.question));
            console.log(this.videodetails);

            if (!this.videodetails || this.videodetails.length === 0) {
               $('.loadingContent1').html('No data found.');
            }
         }
      })
   }

   public myDatePickerOptions: IAngularMyDpOptions = {
      dateFormat: 'dd-mm-yyyy',
   };
   public endDateOptions: IAngularMyDpOptions = {
      dateFormat: 'dd-mm-yyyy',
   };

   onStartDateChange() {
      if (this.start_date != null) {
         const startDate = formatDate(this.start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
         this.endDateOptions = {
            ...this.endDateOptions,
            disableUntil: {
               year: this.start_date.singleDate.date.year,
               month: this.start_date.singleDate.date.month,
               day: this.start_date.singleDate.date.day - 1
            }
         };
         // If end date is before start date, reset end date
         if (this.end_date && this.end_date.singleDate.jsDate < this.start_date.singleDate.jsDate) {
            this.end_date = null;
         }
      }
      // Apply filter when both start and end dates are selected
      if (this.start_date != null && this.end_date != null) {
         let startDate = formatDate(this.start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
         let toDate = formatDate(this.end_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
         this.userdetails = this.originalUserDetails.filter(item => {
            let date = formatDate(item.created_at, 'yyyy-MM-dd', 'en-US');
            return date > startDate && date < toDate;
         });
      }
   }

   onFilter() {
      if (this.start_date != null && this.end_date != null) {
         let startDate = formatDate(this.start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
         let toDate = formatDate(this.end_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
         this.userdetails = this.originalUserDetails.filter(item => {
            let date = formatDate(item.created_at, 'yyyy-MM-dd', 'en-US');
            return date > startDate && date < toDate;
         });
      }
   }


   public myVideoDatePickerOptions: IAngularMyDpOptions = {
      dateFormat: 'dd-mm-yyyy',
   };
   public myVideoEndDateOptions: IAngularMyDpOptions = {
      dateFormat: 'dd-mm-yyyy',
   };

   onVideoStartDateChange() {
      if (this.video_start_date != null) {
         const startDate = formatDate(this.video_start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
         this.myVideoEndDateOptions = {
            ...this.myVideoEndDateOptions,
            disableUntil: {
               year: this.video_start_date.singleDate.date.year,
               month: this.video_start_date.singleDate.date.month,
               day: this.video_start_date.singleDate.date.day - 1
            }
         };
         // If end date is before start date, reset end date
         if (this.video_end_date && this.video_end_date.singleDate.jsDate < this.video_start_date.singleDate.jsDate) {
            this.video_end_date = null;
         }
      }
      // Apply filter when both start and end dates are selected
      if (this.video_start_date != null && this.video_end_date != null) {
         let startDate = formatDate(this.video_start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
         let toDate = formatDate(this.video_end_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
         this.videodetails = this.originalVideoDetails.filter(item => {

            let date = formatDate(item.created_at, 'yyyy-MM-dd', 'en-US');
            return date > startDate && date < toDate;

         });
      }
   }

   onVideoFilter() {
      if (this.video_start_date != null && this.video_end_date != null) {
         let startDate = formatDate(this.video_start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
         let toDate = formatDate(this.video_end_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
         this.videodetails = this.originalVideoDetails.filter(item => {

            let date = formatDate(item.created_at, 'yyyy-MM-dd', 'en-US');
            return date > startDate && date < toDate;

         });
      }

   }


   public myCategoryDatePickerOptions: IAngularMyDpOptions = {
      dateFormat: 'dd-mm-yyyy',
   };
   public myCategoryEndDateOptions: IAngularMyDpOptions = {
      dateFormat: 'dd-mm-yyyy',
   };

   onCategoryStartDateChange() {
      if (this.category_start_date != null) {
         const startDate = formatDate(this.category_start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
         this.myCategoryEndDateOptions = {
            ...this.myCategoryEndDateOptions,
            disableUntil: {
               year: this.category_start_date.singleDate.date.year,
               month: this.category_start_date.singleDate.date.month,
               day: this.category_start_date.singleDate.date.day - 1
            }
         };
         // If end date is before start date, reset end date
         if (this.category_end_date && this.category_end_date.singleDate.jsDate < this.category_start_date.singleDate.jsDate) {
            this.category_end_date = null;
         }
      }
      // Apply filter when both start and end dates are selected
      if (this.category_start_date != null && this.category_end_date != null) {
         let startDate = formatDate(this.category_start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
         let toDate = formatDate(this.category_end_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
         this.categoryDetails = this.originalCategoryDetails.filter(item => {
            let date = formatDate(item.created_at, 'yyyy-MM-dd', 'en-US');
            return date > startDate && date < toDate;
         });

      }
   }

   onCategoryFilter() {
      if (this.category_start_date != null && this.category_end_date != null) {

         let startDate = formatDate(this.category_start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
         let toDate = formatDate(this.category_end_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
         this.categoryDetails = this.originalCategoryDetails.filter(item => {
            let date = formatDate(item.created_at, 'yyyy-MM-dd', 'en-US');
            return date > startDate && date < toDate;
         });

      }
   }

   onSubmit() {
      if (this.start_date && this.end_date) {
         let project_id = localStorage.getItem("currentUser_projectId");
         let fromDate = formatDate(this.start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
         let toDate = formatDate(this.end_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
         // if(this.exportForm.valid){
         //   fromDate = formatDate(value.project_start_date, 'yyyy-MM-dd', 'en-US');
         //   toDate = formatDate(value.project_end_date, 'yyyy-MM-dd', 'en-US');
         // } 
         console.log(environment.apiUrl + '/export_excel_date?project_id=' + project_id + '&from=' + fromDate + '&to=' + toDate);
         window.open(environment.apiUrl + '/export_excel_date?project_id=' + project_id + '&from=' + fromDate + '&to=' + toDate, "_self");
         this.start_date = ""
         this.end_date = ""
      }
      else {
         let project_id = localStorage.getItem("currentUser_projectId");
         console.log(environment.apiUrl + '/export_excel_date?project_id=' + project_id + '&from=' + null + '&to=' + null);
         window.open(environment.apiUrl + '/export_excel_date?project_id=' + project_id + '&from=' + null + '&to=' + null, "_self");
         this.start_date = ""
         this.end_date = ""
      }
   }

   onvideoSubmit() {
      if (this.video_start_date && this.video_end_date) {

         let project_id = localStorage.getItem("currentUser_projectId");
         let fromDate = formatDate(this.video_start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
         let toDate = formatDate(this.video_end_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
         console.log(environment.apiUrl + '/video_excel_date?project_id=' + project_id + '&from=' + fromDate + '&to=' + toDate);
         window.open(environment.apiUrl + '/video_excel_date?project_id=' + project_id + '&from=' + fromDate + '&to=' + toDate, "_self")
         this.video_start_date = "";
         this.video_end_date = "";
      }
      else {
         let project_id = localStorage.getItem("currentUser_projectId");
         console.log(environment.apiUrl + '/video_excel_date?project_id=' + project_id + '&from=' + null + '&to=' + null);
         window.open(environment.apiUrl + '/video_excel_date?project_id=' + project_id + '&from=' + null + '&to=' + null, "_self")
         this.video_start_date = "";
         this.video_end_date = "";
      }
   }

   onCategorySubmit() {
      console.log("category_start_date=>", this.category_start_date);
      console.log("category_end_date=>", this.category_end_date);
      console.log("category_ cat_id=>", this.onFilteredId);

      if (this.category_start_date && this.category_end_date) {

         let project_id = localStorage.getItem("currentUser_projectId");
         let fromDate = formatDate(this.category_start_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
         let toDate = formatDate(this.category_end_date.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
         console.log(environment.apiUrl + '/export_dy_excel_date?project_id=' + project_id + '&from=' + fromDate + '&to=' + toDate + '&cat_id=' + this.onFilteredId);
         window.open(environment.apiUrl + '/export_dy_excel_date?project_id=' + project_id + '&from=' + fromDate + '&to=' + toDate + '&cat_id=' + this.onFilteredId, "_self")
         this.category_start_date = "";
         this.category_end_date = "";
      }
      else {
         let project_id = localStorage.getItem("currentUser_projectId");
         console.log(environment.apiUrl + '/export_dy_excel_date?project_id=' + project_id + '&from=' + null + '&to=' + null + '&cat_id=' + this.onFilteredId);
         window.open(environment.apiUrl + '/export_dy_excel_date?project_id=' + project_id + '&from=' + null + '&to=' + null + '&cat_id=' + this.onFilteredId, "_self")
         this.category_start_date = "";
         this.category_end_date = "";
      }
   }

   copyTextToClipboard(text) {
      var txtArea = document.createElement("textarea");
      txtArea.id = 'txt';
      txtArea.style.position = 'fixed';
      txtArea.style.top = '0';
      txtArea.style.left = '0';
      txtArea.style.opacity = '0';
      txtArea.value = text;
      document.body.appendChild(txtArea);
      txtArea.select();

      try {
         var successful = document.execCommand('copy');
         var msg = successful ? 'successful' : 'unsuccessful';
         console.log('Copying text command was ' + msg);
         if (successful) {
            return true;
         }
      } catch (err) {
         console.log('Oops, unable to copy');
      } finally {
         document.body.removeChild(txtArea);
      }
      return false;
   }


   copyLink() {
      let project_id = localStorage.getItem("currentUser_projectId");
      this.textToCopy = (environment.apiUrl + '/project_export?project_id=' + project_id);;
      var result = this.copyTextToClipboard(this.textToCopy);
      if (result) {
         this.toastrService.success('Copied to Clipboard');
      }

   }


   openFormModel(userdata) {
      localStorage.setItem('userDetails', JSON.stringify(userdata));
   }

   openVideoModel(videodata) {
      localStorage.setItem('videoDetails', JSON.stringify(videodata));
   }

   openCategoryModel(categoryData) {
      localStorage.setItem('categoryDetails', JSON.stringify(categoryData));
   }



   categoryData: any;
   public getCategoryLists() {
      this.formdetails.getCategoryDetails().subscribe(
         {
            next: (res: any) => {
               if (res.success == 1) {
                  this.categoryData = res.data;
                  console.log("this.categoryData=>", this.categoryData);
                  this.loader = false;
               }
               else { }
            },
            error: (error: any) => { }
         });
   }

   activeTabIndex: number = 0;
   setActiveTab(index: number, item: any) {
      console.log("item->id", item.id);
      this.activeTabIndex = index;
      this.onFilteredId = item.id;
      localStorage.setItem("categoryId", this.onFilteredId)
      console.log("in set tab itemID->", this.onFilteredId);
      this.getCategoryListsById(this.onFilteredId)
   }


   categoryDataByID: any;
   public getCategoryListsById(catID: any) {
      jQuery('.loadingContent2').html('<span class="loading"></span>');
      let catObj =
      {
         'cat_id': catID,
         'project_id': localStorage.getItem('currentUser_projectId')
      }
      this.formdetails.getCategoryDetailsByCatId(catObj).subscribe(
         {
            next: (res: any) => {
               if (res.success == 1) {
                  this.categoryDataByID = res.data;
                  console.log("this.categoryDataByID by id-> data=>", res.data);
                  console.log("this.categoryDataByID by-> question=>", res.question);
                  // console.log("this.categoryDataByID by localstorage question parse=>", JSON.parse(res.question));
                  // console.log("this.categoryDataByID by localstorage question stringify=>", JSON.stringify(res.question));
                  console.log("this.categoryDataByID by-> question/.length=>", res.question.length);

                  this.originalCategoryDetails = JSON.parse(JSON.stringify(this.categoryDataByID));
                  localStorage.setItem('categoryQuestionList', JSON.stringify(res.question));

                  // if (res.question.length === 0) {
                  //    console.log("in 0");

                  //    let dummyData =[
                  //       "Which of the following is an example of personal protective equipment (PPE) in construction?",
                  //       "Which of the following is a common cause of electrical hazards in construction?",
                  //       "How do you evaluate the risk ?",
                  //       "How do you decide on precaution?"
                  //    ]
                  //    localStorage.setItem('categoryQuestionList', JSON.stringify(dummyData));
                  // }
                  // else {
                  //    console.log("not 0");

                  //    localStorage.setItem('categoryQuestionList', JSON.stringify(res.question));

                  // }

                  this.loader = false;

                  if (!this.categoryDataByID || this.categoryDataByID.length === 0) {
                     $('.loadingContent2').html('No data found.');
                  }
               }
               else {

               }
            },
            error: (error: any) => { }
         });
   }


   onFilteredData: any[];
   onFilteredId: any;

   onMainTabChange(tabName) {
      console.log("tabName=>>>>>>>>>", tabName);

      if (tabName == 'categoryForm') {
         console.log("in If categoryForm");

         if (this.categoryData.length > 0) {
            this.onFilteredData = this.categoryData.filter(item => item.is_default === 1);
            if (this.onFilteredData.length > 0) {
               // this.firstItemId = this.onFilteredData[0].id;
               console.log("item.id->>>>>>>>>", this.onFilteredData[0].id);
               this.onFilteredId = this.onFilteredData[0].id
               localStorage.setItem("categoryId", this.onFilteredId)
               console.log(" this.onFilteredId", this.onFilteredData[0].id);
               this.getCategoryListsById(this.onFilteredId)
            }
            // console.log("this.onFilteredDataonFilteredData=>", this.onFilteredData);
         }



      }

   }
}
